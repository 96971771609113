var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-form" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isShowLoading,
              expression: "isShowLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "validate-on-rule-change": false,
            disabled: _vm.isDisabled,
            "data-value": _vm.entry
          }
        },
        _vm._l(_vm.filterShow(_vm.set), function(item) {
          return _c(
            "div",
            {
              key: item.key,
              class: {
                "item-box-50": item.isCol50,
                "item-box-33": item.isCol33,
                "item-box-50-4": item.isCol50Label4,
                "item-box-100-4": item.isCol100Label4,
                "item-box-def":
                  !item.isCol50 &&
                  item.isCol33 &&
                  !item.isCol50Label4 &&
                  !item.isCol100Label4,
                "item-hidden": item.isHidden
              }
            },
            [
              item.type == "TitleName"
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "TitleName_title",
                      attrs: {
                        label: item.label,
                        prop: item.type != "table" ? item.key : null
                      }
                    },
                    [
                      item.type == "TitleName"
                        ? _c("div", { staticClass: "form-item_TitleName" }, [
                            _vm._v(_vm._s(item.subLabel))
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              item.type == "ViewDetails"
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "ViewDetails_title",
                      attrs: {
                        label: item.label,
                        prop: item.type != "table" ? item.key : null
                      }
                    },
                    [
                      item.type == "ViewDetails"
                        ? _c(
                            "div",
                            {
                              staticClass: "form-item_ViewDetails",
                              on: {
                                click: function($event) {
                                  item.opt.onCheck()
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.subLabel))]
                          )
                        : _vm._e()
                    ]
                  )
                : item.type == "selectTip"
                  ? _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !item.notShow,
                            expression: "!item.notShow"
                          }
                        ],
                        attrs: {
                          label: item.label,
                          prop: item.type != "table" ? item.key : null
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            class: { "item-content-67": item.isWidth67 },
                            attrs: {
                              placeholder:
                                _vm.type != "show" && !item.disabled
                                  ? "请选择" + item.label
                                  : "",
                              disabled: item.disabled
                            },
                            on: {
                              change: function($event) {
                                _vm.onChangeSelect(item)
                              }
                            },
                            model: {
                              value: _vm.form[item.key],
                              callback: function($$v) {
                                _vm.$set(_vm.form, item.key, $$v)
                              },
                              expression: "form[item.key]"
                            }
                          },
                          _vm._l(item.opt.list, function(it) {
                            return _c("el-option", {
                              key: it.value,
                              attrs: { label: it.label, value: it.value }
                            })
                          }),
                          1
                        ),
                        _c("div", { staticClass: "select__tip" }, [
                          _vm._v(_vm._s(item.opt.tip))
                        ])
                      ],
                      1
                    )
                  : _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !item.notShow,
                            expression: "!item.notShow"
                          }
                        ],
                        attrs: {
                          label: item.label,
                          prop: item.type != "table" ? item.key : null
                        }
                      },
                      [
                        !item.type
                          ? _c("el-input", {
                              class: { "item-content-67": item.isWidth67 },
                              attrs: {
                                disabled: item.disabled ? true : false,
                                clearable: "",
                                placeholder:
                                  _vm.type != "show" && !item.disabled
                                    ? item.placeholder
                                      ? item.placeholder
                                      : "请填写" + item.label
                                    : "",
                                maxlength: item.maxlength ? item.maxlength : 50
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  _vm.keyUpChange(item)
                                }
                              },
                              model: {
                                value: _vm.form[item.key],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    item.key,
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form[item.key]"
                              }
                            })
                          : item.type == "subTitle"
                            ? _c("div", { staticClass: "form-item_subTitle" }, [
                                _vm._v(_vm._s(item.subLabel))
                              ])
                            : item.type == "textarea"
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    disabled: item.disabled ? true : false,
                                    rows: 4,
                                    maxlength: item.maxlength
                                      ? item.maxlength
                                      : 1000,
                                    placeholder:
                                      _vm.type != "show"
                                        ? item.placeholder
                                          ? item.placeholder
                                          : "请填写" + item.label
                                        : "",
                                    "show-word-limit": "",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.form[item.key],
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, item.key, $$v)
                                    },
                                    expression: "form[item.key]"
                                  }
                                })
                              : item.type == "number"
                                ? _c("el-input-number", {
                                    class: {
                                      "item-content-67": item.isWidth67,
                                      "item-content-100": item.isWidth100
                                    },
                                    attrs: {
                                      type: "number",
                                      min: item.min != null ? item.min : 1,
                                      max: item.max != null ? item.max : 9999,
                                      label: item.label
                                        ? item.label
                                        : "描述文字",
                                      placeholder:
                                        _vm.type != "show"
                                          ? item.placeholder
                                            ? item.placeholder
                                            : "请填写" + item.label
                                          : "",
                                      disabled: item.disabled ? true : false,
                                      clearable: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.numberChange(item)
                                      }
                                    },
                                    model: {
                                      value: _vm.form[item.key],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          item.key,
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "form[item.key]"
                                    }
                                  })
                                : item.type == "link"
                                  ? _c(
                                      "div",
                                      {
                                        class:
                                          _vm.type == "show"
                                            ? "input-link"
                                            : "",
                                        on: {
                                          click: function($event) {
                                            item.opt.callback(_vm.form)
                                          }
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: item.disabled
                                              ? true
                                              : false,
                                            type: "text",
                                            placeholder:
                                              _vm.type != "show"
                                                ? item.placeholder
                                                  ? item.placeholder
                                                  : "请填写" + item.label
                                                : ""
                                          },
                                          model: {
                                            value: _vm.form[item.key],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                item.key,
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "form[item.key]"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : item.type == "password"
                                    ? _c("el-input", {
                                        class: {
                                          "item-content-67": item.isWidth67
                                        },
                                        attrs: {
                                          disabled: item.disabled
                                            ? true
                                            : false,
                                          type: "password",
                                          clearable: "",
                                          placeholder:
                                            _vm.type != "show"
                                              ? item.placeholder
                                                ? item.placeholder
                                                : "请填写" + item.label
                                              : "",
                                          maxlength: item.maxlength
                                            ? item.maxlength
                                            : 50,
                                          readonly: _vm.pwReadonlyFlag
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.pwReadonlyFlagBtn($event)
                                          }
                                        },
                                        model: {
                                          value: _vm.form[item.key],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              item.key,
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form[item.key]"
                                        }
                                      })
                                    : item.type == "userPassword"
                                      ? _c("el-input", {
                                          class: {
                                            "item-content-67": item.isWidth67
                                          },
                                          attrs: {
                                            maxlength: item.maxlength
                                              ? item.maxlength
                                              : 50,
                                            disabled: item.disabled
                                              ? true
                                              : false,
                                            type: "password",
                                            clearable: "",
                                            placeholder:
                                              _vm.type != "show"
                                                ? item.placeholder
                                                  ? item.placeholder
                                                  : "请填写" + item.label
                                                : "",
                                            readonly: _vm.pwReadonlyFlag
                                          },
                                          on: {
                                            focus: function($event) {
                                              _vm.focusFun(item)
                                            }
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.pwReadonlyFlagBtn(
                                                $event
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.form[item.key],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                item.key,
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "form[item.key]"
                                          }
                                        })
                                      : item.type == "selectStores"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "jump-box",
                                              class: {
                                                "item-content-67":
                                                  item.isWidth67
                                              },
                                              attrs: {
                                                disabled: false,
                                                clearable: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.jump(
                                                    item,
                                                    _vm.form[item.key]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectStoresShow
                                                    ? "请先添加用户提交，再编辑用户【授权货柜】"
                                                    : _vm.form[item.key]
                                                ) + "\n        "
                                              )
                                            ]
                                          )
                                        : item.type == "otherPassword"
                                          ? _c("el-input", {
                                              staticClass: "no-autofill-pwd",
                                              class: {
                                                "item-content-67":
                                                  item.isWidth67
                                              },
                                              attrs: {
                                                maxlength: item.maxlength
                                                  ? item.maxlength
                                                  : 50,
                                                disabled: item.disabled
                                                  ? true
                                                  : false,
                                                type: "text",
                                                clearable: "",
                                                placeholder:
                                                  _vm.type != "show"
                                                    ? item.placeholder
                                                      ? item.placeholder
                                                      : "请填写" + item.label
                                                    : "",
                                                readonly: _vm.pwReadonlyFlag
                                              },
                                              on: {
                                                focus: function($event) {
                                                  _vm.focusFun(item)
                                                }
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.pwReadonlyFlagBtn(
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.form[item.key],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    item.key,
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "form[item.key]"
                                              }
                                            })
                                          : item.type == "select-filterables"
                                            ? _c(
                                                "el-select",
                                                {
                                                  class: {
                                                    "item-content-67":
                                                      item.isWidth67
                                                  },
                                                  attrs: {
                                                    multiple:
                                                      item.opt.multiple == false
                                                        ? false
                                                        : true,
                                                    filterable: "",
                                                    "filter-method": _vm.selectRemoteFilterMethod(
                                                      item
                                                    ),
                                                    placeholder:
                                                      _vm.type != "show"
                                                        ? "请输入关键词搜索"
                                                        : "",
                                                    "remote-method":
                                                      _vm.onSelectRemoteMethod,
                                                    loading: item.opt.isLoading,
                                                    disabled: item.disabled,
                                                    "popper-class": item.maxHeight
                                                      ? item.maxHeight
                                                      : "",
                                                    "popper-append-to-body": true
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      _vm.onChangeSelect(item)
                                                    },
                                                    focus: function($event) {
                                                      _vm.onSelectRemotefocus(
                                                        item
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.form[item.key],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        item.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form[item.key]"
                                                  }
                                                },
                                                _vm._l(item.opt.list, function(
                                                  it
                                                ) {
                                                  return _c("el-option", {
                                                    key: it.value,
                                                    attrs: {
                                                      label: it.label,
                                                      value: it.value
                                                    }
                                                  })
                                                }),
                                                1
                                              )
                                            : item.type == "select-filterable"
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    class: {
                                                      "item-content-67":
                                                        item.isWidth67
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      "filter-method": _vm.selectRemoteFilterMethod(
                                                        item
                                                      ),
                                                      placeholder:
                                                        _vm.type != "show"
                                                          ? "请输入关键词搜索"
                                                          : "",
                                                      "remote-method":
                                                        _vm.onSelectRemoteMethod,
                                                      loading:
                                                        item.opt.isLoading,
                                                      disabled: item.disabled
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        _vm.onChangeSelect(item)
                                                      },
                                                      focus: function($event) {
                                                        _vm.onSelectRemotefocus(
                                                          item
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.form[item.key],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          item.key,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[item.key]"
                                                    }
                                                  },
                                                  _vm._l(
                                                    item.opt.list,
                                                    function(it) {
                                                      return _c("el-option", {
                                                        key: it.value,
                                                        attrs: {
                                                          label: it.label,
                                                          value: it.value
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              : item.type ==
                                                "select-filterable-2"
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      class: {
                                                        "item-content-67":
                                                          item.isWidth67
                                                      },
                                                      attrs: {
                                                        multiple:
                                                          item.opt.multiple ==
                                                          false
                                                            ? false
                                                            : true,
                                                        filterable: "",
                                                        placeholder:
                                                          _vm.type != "show"
                                                            ? "请输入关键词搜索"
                                                            : "",
                                                        "remote-method":
                                                          _vm.onSelectRemoteMethod,
                                                        loading:
                                                          item.opt.isLoading,
                                                        disabled: item.disabled
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.onChangeSelect(
                                                            item
                                                          )
                                                        },
                                                        focus: function(
                                                          $event
                                                        ) {
                                                          _vm.onSelectRemotefocus(
                                                            item
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[item.key],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            item.key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[item.key]"
                                                      }
                                                    },
                                                    _vm._l(
                                                      item.opt.list,
                                                      function(it) {
                                                        return _c("el-option", {
                                                          key: it.value,
                                                          attrs: {
                                                            label: it.label,
                                                            value: it.value
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : item.type == "select-remote"
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        class: {
                                                          "item-content-67":
                                                            item.isWidth67
                                                        },
                                                        attrs: {
                                                          multiple:
                                                            item.opt.multiple ==
                                                            false
                                                              ? false
                                                              : true,
                                                          filterable: "",
                                                          remote: "",
                                                          "reserve-keyword": "",
                                                          placeholder:
                                                            _vm.type != "show"
                                                              ? "请输入关键词搜索"
                                                              : "",
                                                          "remote-method":
                                                            _vm.onSelectRemoteMethod,
                                                          loading:
                                                            item.opt.isLoading,
                                                          disabled:
                                                            item.disabled
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            _vm.onChangeSelect(
                                                              item
                                                            )
                                                          },
                                                          focus: function(
                                                            $event
                                                          ) {
                                                            _vm.onSelectRemotefocus(
                                                              item
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[item.key],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              item.key,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[item.key]"
                                                        }
                                                      },
                                                      _vm._l(
                                                        item.opt.list,
                                                        function(it) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: it.value,
                                                              attrs: {
                                                                label: it.label,
                                                                value: it.value
                                                              }
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : item.type ==
                                                    "select-remote-filterable"
                                                    ? _c(
                                                        "el-select",
                                                        {
                                                          class: {
                                                            "item-content-67":
                                                              item.isWidth67
                                                          },
                                                          attrs: {
                                                            multiple:
                                                              item.opt
                                                                .multiple ==
                                                              false
                                                                ? false
                                                                : true,
                                                            filterable: "",
                                                            "filter-method": _vm.handleSelectRemoteFilterable(
                                                              item
                                                            ),
                                                            placeholder:
                                                              _vm.type != "show"
                                                                ? "请输入关键词搜索"
                                                                : "",
                                                            loading:
                                                              item.opt
                                                                .isLoading,
                                                            disabled:
                                                              item.disabled
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              _vm.handleSelectRemoteFilterableChange(
                                                                item
                                                              )
                                                            },
                                                            focus: function(
                                                              $event
                                                            ) {
                                                              _vm.handleSelectRemoteFilterableFocus(
                                                                item
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form[
                                                                item.key
                                                              ],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                item.key,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form[item.key]"
                                                          }
                                                        },
                                                        _vm._l(
                                                          item.opt.list,
                                                          function(it) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: it.value,
                                                                attrs: {
                                                                  label:
                                                                    it.label,
                                                                  value:
                                                                    it.value
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "select-label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        it.label
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "select-subLabel"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        it.subLabel ||
                                                                          ""
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : item.type ==
                                                      "select-filter"
                                                      ? _c(
                                                          "el-select",
                                                          {
                                                            class: {
                                                              "item-content-67":
                                                                item.isWidth67
                                                            },
                                                            attrs: {
                                                              filterable: "",
                                                              multiple:
                                                                item.opt
                                                                  .multiple ==
                                                                false
                                                                  ? false
                                                                  : true,
                                                              placeholder:
                                                                _vm.type !=
                                                                "show"
                                                                  ? "请输入关键词搜索"
                                                                  : ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                _vm.handleSelectRemoteFilterableChange(
                                                                  item
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  item.key
                                                                ],
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.form,
                                                                  item.key,
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "form[item.key]"
                                                            }
                                                          },
                                                          _vm._l(
                                                            item.opt.list,
                                                            function(it) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: it.value,
                                                                  attrs: {
                                                                    label:
                                                                      it.label,
                                                                    value:
                                                                      it.value
                                                                  }
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      : item.type == "switch"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  disabled:
                                                                    item.disabled,
                                                                  "active-color":
                                                                    "#67C23A",
                                                                  "inactive-color":
                                                                    "#F56C6C",
                                                                  "active-text":
                                                                    item.opt &&
                                                                    item.opt
                                                                      .activeText
                                                                      ? item.opt
                                                                          .activeText
                                                                      : "启用",
                                                                  "inactive-text":
                                                                    item.opt &&
                                                                    item.opt
                                                                      .inactiveText
                                                                      ? item.opt
                                                                          .inactiveText
                                                                      : "禁用"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.onChangeSelect(
                                                                      item
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form[
                                                                      item.key
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      item.key,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "form[item.key]"
                                                                }
                                                              }),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tipsText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.subLabel
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : item.type ==
                                                          "checkbox"
                                                          ? _c(
                                                              "el-checkbox-group",
                                                              {
                                                                attrs: {
                                                                  size: "small"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form[
                                                                      item.key
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      item.key,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "form[item.key]"
                                                                }
                                                              },
                                                              _vm._l(
                                                                item.opt.list,
                                                                function(it) {
                                                                  return _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      key:
                                                                        it.id,
                                                                      attrs: {
                                                                        label:
                                                                          it.id,
                                                                        border:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          it.name
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : item.type ==
                                                            "selectCity"
                                                            ? _c(
                                                                "el-select",
                                                                {
                                                                  class: {
                                                                    "item-content-67":
                                                                      item.isWidth67
                                                                  },
                                                                  attrs: {
                                                                    filterable:
                                                                      "",
                                                                    placeholder:
                                                                      _vm.type !=
                                                                      "show"
                                                                        ? "请选择" +
                                                                          item.label
                                                                        : "",
                                                                    disabled:
                                                                      item.disabled
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.onChangeSelect(
                                                                        item
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form[
                                                                        item.key
                                                                      ],
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        item.key,
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "form[item.key]"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  item.opt.list,
                                                                  function(it) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          it.value,
                                                                        attrs: {
                                                                          label:
                                                                            it.label,
                                                                          value:
                                                                            it.label
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : item.type ==
                                                              "select"
                                                              ? _c(
                                                                  "el-select",
                                                                  {
                                                                    class: {
                                                                      "item-content-67":
                                                                        item.isWidth67
                                                                    },
                                                                    attrs: {
                                                                      placeholder:
                                                                        _vm.type !=
                                                                          "show" &&
                                                                        !item.disabled
                                                                          ? "请选择" +
                                                                            item.label
                                                                          : "",
                                                                      disabled:
                                                                        item.disabled
                                                                    },
                                                                    on: {
                                                                      change: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.onChangeSelect(
                                                                          item
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .form[
                                                                          item
                                                                            .key
                                                                        ],
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.form,
                                                                          item.key,
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "form[item.key]"
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    item.opt
                                                                      .list,
                                                                    function(
                                                                      it
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key:
                                                                            it.value,
                                                                          attrs: {
                                                                            label:
                                                                              it.label,
                                                                            value:
                                                                              it.value
                                                                          }
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              : item.type ==
                                                                "upload-img"
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-upload",
                                                                        {
                                                                          class: {
                                                                            "upload-disabled":
                                                                              _vm.isDisabled
                                                                          },
                                                                          attrs: {
                                                                            action:
                                                                              item
                                                                                .opt
                                                                                .url,
                                                                            accept:
                                                                              "image/*",
                                                                            "list-type":
                                                                              "picture-card",
                                                                            "auto-upload": false,
                                                                            limit: item
                                                                              .opt
                                                                              .limit
                                                                              ? item
                                                                                  .opt
                                                                                  .limit
                                                                              : 6,
                                                                            "file-list":
                                                                              _vm
                                                                                .form[
                                                                                item
                                                                                  .key
                                                                              ],
                                                                            "http-request":
                                                                              item
                                                                                .opt
                                                                                .upload,
                                                                            "before-upload": _vm.onBeforeUpload(
                                                                              item
                                                                            ),
                                                                            "before-remove": _vm.onBeforeRemoveByUploadImg(
                                                                              item
                                                                            ),
                                                                            "on-success": _vm.onSuccessByUploadImg(
                                                                              item
                                                                            ),
                                                                            "on-preview":
                                                                              _vm.onPreviewByUploadImg,
                                                                            "on-exceed":
                                                                              _vm.onExceed,
                                                                            disabled:
                                                                              _vm.isDisabled
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-plus"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : item.type ==
                                                                  "upload-img-form"
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "el-upload",
                                                                          {
                                                                            class: {
                                                                              "upload-disabled":
                                                                                _vm.isDisabled
                                                                            },
                                                                            attrs: {
                                                                              accept:
                                                                                "image/*",
                                                                              "list-type":
                                                                                "picture-card",
                                                                              action:
                                                                                item
                                                                                  .opt
                                                                                  .url,
                                                                              "file-list":
                                                                                _vm
                                                                                  .form[
                                                                                  item
                                                                                    .key
                                                                                ],
                                                                              limit: item
                                                                                .opt
                                                                                .limit
                                                                                ? item
                                                                                    .opt
                                                                                    .limit
                                                                                : 6,
                                                                              "on-preview":
                                                                                _vm.onPreviewByUploadImg,
                                                                              "before-upload": _vm.onBeforeUpload(
                                                                                item
                                                                              ),
                                                                              name: item
                                                                                .opt
                                                                                .name
                                                                                ? item
                                                                                    .opt
                                                                                    .name
                                                                                : "file",
                                                                              "before-remove": _vm.onBeforeRemoveByUploadImg(
                                                                                item
                                                                              ),
                                                                              "on-success": _vm.onSuccessByFormUploadImg(
                                                                                item
                                                                              ),
                                                                              "on-remove": _vm.onHandleRemove(
                                                                                item
                                                                              ),
                                                                              "on-exceed":
                                                                                _vm.onExceed,
                                                                              disabled:
                                                                                _vm.isDisabled
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "el-icon-plus"
                                                                              }
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "img-upload-alt"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item
                                                                                  .opt
                                                                                  .alt
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : item.type ==
                                                                    "upload-img-only"
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm.isDisabled
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "disabled-box"
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "el-upload",
                                                                            {
                                                                              staticClass:
                                                                                "avatar-uploader",
                                                                              attrs: {
                                                                                accept:
                                                                                  "image/*",
                                                                                "list-type":
                                                                                  "picture-card",
                                                                                action:
                                                                                  item
                                                                                    .opt
                                                                                    .url,
                                                                                "file-list":
                                                                                  _vm
                                                                                    .form[
                                                                                    item
                                                                                      .key
                                                                                  ],
                                                                                "show-file-list": false,
                                                                                "on-preview":
                                                                                  _vm.onPreviewByUploadImg,
                                                                                "before-upload": _vm.onBeforeUpload(
                                                                                  item
                                                                                ),
                                                                                "before-remove": _vm.onBeforeRemoveByUploadImg(
                                                                                  item
                                                                                ),
                                                                                "on-success": _vm.onSuccessByUploadImgOnly(
                                                                                  item
                                                                                ),
                                                                                "on-remove": _vm.onHandleRemove(
                                                                                  item
                                                                                ),
                                                                                "on-exceed":
                                                                                  _vm.onExceed
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .form[
                                                                                item
                                                                                  .key
                                                                              ] &&
                                                                              _vm
                                                                                .form[
                                                                                item
                                                                                  .key
                                                                              ]
                                                                                .length
                                                                                ? _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "avatar",
                                                                                      attrs: {
                                                                                        src: item
                                                                                          .opt
                                                                                          .src
                                                                                          ? item
                                                                                              .opt
                                                                                              .src +
                                                                                            _vm
                                                                                              .form[
                                                                                              item
                                                                                                .key
                                                                                            ][0]
                                                                                              .url
                                                                                          : _vm
                                                                                              .form[
                                                                                              item
                                                                                                .key
                                                                                            ][0]
                                                                                              .url
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                : _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "el-icon-plus"
                                                                                    }
                                                                                  )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "img-upload-alt"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item
                                                                                    .opt
                                                                                    .altKey
                                                                                    ? item
                                                                                        .opt
                                                                                        .alt[
                                                                                        _vm
                                                                                          .form[
                                                                                          item
                                                                                            .opt
                                                                                            .altKey
                                                                                        ]
                                                                                      ]
                                                                                    : item
                                                                                        .opt
                                                                                        .alt
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : item.type ==
                                                                      "upload-img-only-one"
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _vm.isDisabled
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "disabled-box"
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "el-upload",
                                                                              {
                                                                                staticClass:
                                                                                  "avatar-uploader",
                                                                                class: {
                                                                                  "one-upload-disabled":
                                                                                    _vm
                                                                                      .form[
                                                                                      item
                                                                                        .key
                                                                                    ]
                                                                                      .length >
                                                                                    0
                                                                                },
                                                                                attrs: {
                                                                                  accept:
                                                                                    "image/*",
                                                                                  "list-type":
                                                                                    "picture-card",
                                                                                  action:
                                                                                    item
                                                                                      .opt
                                                                                      .url,
                                                                                  "file-list":
                                                                                    _vm
                                                                                      .form[
                                                                                      item
                                                                                        .key
                                                                                    ],
                                                                                  "on-preview":
                                                                                    _vm.onPreviewByUploadImg,
                                                                                  "before-upload": _vm.onBeforeUpload(
                                                                                    item
                                                                                  ),
                                                                                  "before-remove": _vm.onBeforeRemoveByUploadImg(
                                                                                    item
                                                                                  ),
                                                                                  "on-success": _vm.onSuccessByUploadImgOnly(
                                                                                    item
                                                                                  ),
                                                                                  "on-remove": _vm.onHandleRemove(
                                                                                    item
                                                                                  ),
                                                                                  "on-exceed":
                                                                                    _vm.onExceed
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .form[
                                                                                  item
                                                                                    .key
                                                                                ]
                                                                                  .length <
                                                                                1
                                                                                  ? _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "el-icon-plus"
                                                                                      }
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : item.type ==
                                                                        "cascader"
                                                                        ? _c(
                                                                            "el-cascader",
                                                                            {
                                                                              attrs: {
                                                                                "collapse-tags":
                                                                                  item.collapseTags,
                                                                                options:
                                                                                  item
                                                                                    .opt
                                                                                    .list,
                                                                                props:
                                                                                  item
                                                                                    .opt
                                                                                    .props,
                                                                                separator:
                                                                                  "/",
                                                                                filterable:
                                                                                  item.filterable
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.onChangeSelect(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .form[
                                                                                    item
                                                                                      .key
                                                                                  ],
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    item.key,
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "form[item.key]"
                                                                              }
                                                                            }
                                                                          )
                                                                        : item.type ==
                                                                          "cascader-confirm"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "cascader-confirm-box",
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    item.opt &&
                                                                                    item
                                                                                      .opt
                                                                                      .click
                                                                                      ? item.opt.click(
                                                                                          _vm
                                                                                            .form[
                                                                                            item
                                                                                              .key
                                                                                          ]
                                                                                        )
                                                                                      : null
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .form[
                                                                                      item
                                                                                        .key
                                                                                    ]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : item.type ==
                                                                            "cascader-lazy"
                                                                            ? _c(
                                                                                "lazy-cascader",
                                                                                {
                                                                                  attrs: {
                                                                                    disabled:
                                                                                      _vm.isDisabled,
                                                                                    filterable:
                                                                                      "",
                                                                                    props:
                                                                                      item
                                                                                        .opt
                                                                                        .props,
                                                                                    lazyChangeFlag:
                                                                                      _vm.lazyChangeFlag,
                                                                                    separator:
                                                                                      "/"
                                                                                  },
                                                                                  on: {
                                                                                    change:
                                                                                      _vm.lazyCascaderChange
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm
                                                                                        .form[
                                                                                        item
                                                                                          .key
                                                                                      ],
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.form,
                                                                                        item.key,
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "form[item.key]"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : item.type ==
                                                                              "select-cascader"
                                                                              ? _c(
                                                                                  "select-cascader",
                                                                                  {
                                                                                    attrs: {
                                                                                      disabled:
                                                                                        _vm.isDisabled,
                                                                                      props:
                                                                                        item
                                                                                          .opt
                                                                                          .props,
                                                                                      separator:
                                                                                        "/"
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .form[
                                                                                          item
                                                                                            .key
                                                                                        ],
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.form,
                                                                                          item.key,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "form[item.key]"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              : item.type ==
                                                                                "select-cascader-advert"
                                                                                ? _c(
                                                                                    "select-cascader-advert",
                                                                                    {
                                                                                      attrs: {
                                                                                        disabled:
                                                                                          _vm.isDisabled,
                                                                                        props:
                                                                                          item
                                                                                            .opt
                                                                                            .props,
                                                                                        separator:
                                                                                          "/"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .form[
                                                                                            item
                                                                                              .key
                                                                                          ],
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.form,
                                                                                            item.key,
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "form[item.key]"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                : item.type ==
                                                                                  "select-cascader-stores"
                                                                                  ? _c(
                                                                                      "select-cascader-stores",
                                                                                      {
                                                                                        attrs: {
                                                                                          disabled:
                                                                                            _vm.isDisabled,
                                                                                          props:
                                                                                            item
                                                                                              .opt
                                                                                              .props,
                                                                                          separator:
                                                                                            "/"
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .form[
                                                                                              item
                                                                                                .key
                                                                                            ],
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.form,
                                                                                              item.key,
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "form[item.key]"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : item.type ==
                                                                                    "select-cascader-gateway"
                                                                                    ? _c(
                                                                                        "select-cascader-gateways",
                                                                                        {
                                                                                          attrs: {
                                                                                            disabled:
                                                                                              _vm.isDisabled,
                                                                                            props:
                                                                                              item
                                                                                                .opt
                                                                                                .props,
                                                                                            separator:
                                                                                              "/"
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .form[
                                                                                                item
                                                                                                  .key
                                                                                              ],
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.form,
                                                                                                item.key,
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "form[item.key]"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    : item.type ==
                                                                                        "date" &&
                                                                                      item.bforeDateDisable
                                                                                      ? _c(
                                                                                          "el-date-picker",
                                                                                          {
                                                                                            class: {
                                                                                              "item-content-50":
                                                                                                item.isWidth50,
                                                                                              "item-content-67":
                                                                                                item.isWidth67
                                                                                            },
                                                                                            attrs: {
                                                                                              type:
                                                                                                "date",
                                                                                              disabled:
                                                                                                item.disabled,
                                                                                              placeholder:
                                                                                                _vm.type !=
                                                                                                "show"
                                                                                                  ? "请选择" +
                                                                                                    item.label
                                                                                                  : "",
                                                                                              "picker-options":
                                                                                                _vm.pickerOptions
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  item
                                                                                                    .key
                                                                                                ],
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.form,
                                                                                                  item.key,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "form[item.key]"
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      : item.type ==
                                                                                        "date"
                                                                                        ? _c(
                                                                                            "el-date-picker",
                                                                                            {
                                                                                              class: {
                                                                                                "item-content-50":
                                                                                                  item.isWidth50,
                                                                                                "item-content-67":
                                                                                                  item.isWidth67
                                                                                              },
                                                                                              attrs: {
                                                                                                type:
                                                                                                  "date",
                                                                                                disabled:
                                                                                                  item.disabled,
                                                                                                placeholder:
                                                                                                  _vm.type !=
                                                                                                  "show"
                                                                                                    ? "请选择" +
                                                                                                      item.label
                                                                                                    : ""
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form[
                                                                                                    item
                                                                                                      .key
                                                                                                  ],
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm.form,
                                                                                                    item.key,
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                                expression:
                                                                                                  "form[item.key]"
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        : item.type ==
                                                                                          "date-time"
                                                                                          ? _c(
                                                                                              "el-date-picker",
                                                                                              {
                                                                                                class: {
                                                                                                  "item-content-67":
                                                                                                    item.isWidth67
                                                                                                },
                                                                                                attrs: {
                                                                                                  type:
                                                                                                    "datetime",
                                                                                                  disabled:
                                                                                                    item.disabled,
                                                                                                  placeholder:
                                                                                                    _vm.type !=
                                                                                                    "show"
                                                                                                      ? "请选择" +
                                                                                                        item.label
                                                                                                      : ""
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      item
                                                                                                        .key
                                                                                                    ],
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm.form,
                                                                                                      item.key,
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "form[item.key]"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          : item.type ==
                                                                                            "time"
                                                                                            ? _c(
                                                                                                "el-time-picker",
                                                                                                {
                                                                                                  class: {
                                                                                                    "item-content-67":
                                                                                                      item.isWidth67
                                                                                                  },
                                                                                                  attrs: {
                                                                                                    disabled:
                                                                                                      item.disabled,
                                                                                                    "value-format":
                                                                                                      item.opt &&
                                                                                                      item
                                                                                                        .opt
                                                                                                        .format
                                                                                                        ? item
                                                                                                            .opt
                                                                                                            .format
                                                                                                        : "HH:mm:ss",
                                                                                                    format:
                                                                                                      item.opt &&
                                                                                                      item
                                                                                                        .opt
                                                                                                        .format
                                                                                                        ? item
                                                                                                            .opt
                                                                                                            .format
                                                                                                        : "HH:mm:ss",
                                                                                                    "picker-options": {
                                                                                                      selectableRange:
                                                                                                        item.opt &&
                                                                                                        item
                                                                                                          .opt
                                                                                                          .min &&
                                                                                                        item
                                                                                                          .opt
                                                                                                          .max
                                                                                                          ? item
                                                                                                              .opt
                                                                                                              .min +
                                                                                                            " - " +
                                                                                                            item
                                                                                                              .opt
                                                                                                              .max
                                                                                                          : ""
                                                                                                    }
                                                                                                  },
                                                                                                  on: {
                                                                                                    change: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      _vm.onChangeTime(
                                                                                                        item,
                                                                                                        _vm
                                                                                                          .form[
                                                                                                          item
                                                                                                            .key
                                                                                                        ]
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .form[
                                                                                                        item
                                                                                                          .key
                                                                                                      ],
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm.form,
                                                                                                        item.key,
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "form[item.key]"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : item.type ==
                                                                                              "range-time"
                                                                                              ? _c(
                                                                                                  "el-time-picker",
                                                                                                  {
                                                                                                    class: {
                                                                                                      "item-content-67":
                                                                                                        item.isWidth67
                                                                                                    },
                                                                                                    attrs: {
                                                                                                      "range-separator":
                                                                                                        "至",
                                                                                                      "is-range":
                                                                                                        "",
                                                                                                      "start-placeholder":
                                                                                                        "开始时间",
                                                                                                      "end-placeholder":
                                                                                                        "结束时间",
                                                                                                      placeholder:
                                                                                                        "选择时间范围",
                                                                                                      "value-format":
                                                                                                        item.opt &&
                                                                                                        item
                                                                                                          .opt
                                                                                                          .format
                                                                                                          ? item
                                                                                                              .opt
                                                                                                              .format
                                                                                                          : "HH:mm:ss",
                                                                                                      format:
                                                                                                        item.opt &&
                                                                                                        item
                                                                                                          .opt
                                                                                                          .format
                                                                                                          ? item
                                                                                                              .opt
                                                                                                              .format
                                                                                                          : "HH:mm:ss"
                                                                                                    },
                                                                                                    on: {
                                                                                                      change: function(
                                                                                                        $event
                                                                                                      ) {
                                                                                                        item.opt &&
                                                                                                        item
                                                                                                          .opt
                                                                                                          .change
                                                                                                          ? item.opt.change(
                                                                                                              _vm
                                                                                                                .form[
                                                                                                                item
                                                                                                                  .key
                                                                                                              ]
                                                                                                            )
                                                                                                          : null
                                                                                                      }
                                                                                                    },
                                                                                                    model: {
                                                                                                      value:
                                                                                                        _vm
                                                                                                          .form[
                                                                                                          item
                                                                                                            .key
                                                                                                        ],
                                                                                                      callback: function(
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          _vm.form,
                                                                                                          item.key,
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                      expression:
                                                                                                        "form[item.key]"
                                                                                                    }
                                                                                                  }
                                                                                                )
                                                                                              : item.type ==
                                                                                                "range-date"
                                                                                                ? _c(
                                                                                                    "el-date-picker",
                                                                                                    {
                                                                                                      class: {
                                                                                                        "item-content-67":
                                                                                                          item.isWidth67
                                                                                                      },
                                                                                                      attrs: {
                                                                                                        type:
                                                                                                          "daterange",
                                                                                                        "unlink-panels":
                                                                                                          "",
                                                                                                        "range-separator":
                                                                                                          "至",
                                                                                                        "start-placeholder":
                                                                                                          "开始日期",
                                                                                                        "end-placeholder":
                                                                                                          "结束日期"
                                                                                                      },
                                                                                                      model: {
                                                                                                        value:
                                                                                                          _vm
                                                                                                            .form[
                                                                                                            item
                                                                                                              .key
                                                                                                          ],
                                                                                                        callback: function(
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.$set(
                                                                                                            _vm.form,
                                                                                                            item.key,
                                                                                                            $$v
                                                                                                          )
                                                                                                        },
                                                                                                        expression:
                                                                                                          "form[item.key]"
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                : item.type ==
                                                                                                  "datetimerange"
                                                                                                  ? _c(
                                                                                                      "el-date-picker",
                                                                                                      {
                                                                                                        class: {
                                                                                                          "item-content-67":
                                                                                                            item.isWidth67
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          type:
                                                                                                            "datetimerange",
                                                                                                          "unlink-panels":
                                                                                                            "",
                                                                                                          "range-separator":
                                                                                                            "至",
                                                                                                          "start-placeholder":
                                                                                                            "开始日期",
                                                                                                          "end-placeholder":
                                                                                                            "结束日期",
                                                                                                          "picker-options": {
                                                                                                            disabledDate: _vm.onDatetimerangeDisabledDate(
                                                                                                              item
                                                                                                            )
                                                                                                          }
                                                                                                        },
                                                                                                        model: {
                                                                                                          value:
                                                                                                            _vm
                                                                                                              .form[
                                                                                                              item
                                                                                                                .key
                                                                                                            ],
                                                                                                          callback: function(
                                                                                                            $$v
                                                                                                          ) {
                                                                                                            _vm.$set(
                                                                                                              _vm.form,
                                                                                                              item.key,
                                                                                                              $$v
                                                                                                            )
                                                                                                          },
                                                                                                          expression:
                                                                                                            "form[item.key]"
                                                                                                        }
                                                                                                      }
                                                                                                    )
                                                                                                  : item.type ==
                                                                                                    "daterange"
                                                                                                    ? _c(
                                                                                                        "el-date-picker",
                                                                                                        {
                                                                                                          class: {
                                                                                                            "item-content-67":
                                                                                                              item.isWidth67
                                                                                                          },
                                                                                                          attrs: {
                                                                                                            type:
                                                                                                              "daterange",
                                                                                                            "unlink-panels":
                                                                                                              "",
                                                                                                            "range-separator":
                                                                                                              "至",
                                                                                                            "start-placeholder":
                                                                                                              "开始日期",
                                                                                                            "end-placeholder":
                                                                                                              "结束日期",
                                                                                                            "picker-options": {
                                                                                                              disabledDate: _vm.onDatetimerangeDisabledDate(
                                                                                                                item
                                                                                                              )
                                                                                                            }
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .form[
                                                                                                                item
                                                                                                                  .key
                                                                                                              ],
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm.form,
                                                                                                                item.key,
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "form[item.key]"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    : item.type ==
                                                                                                      "upload-file"
                                                                                                      ? _c(
                                                                                                          "el-upload",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              action:
                                                                                                                item.opt &&
                                                                                                                item
                                                                                                                  .opt
                                                                                                                  .url
                                                                                                                  ? item
                                                                                                                      .opt
                                                                                                                      .url
                                                                                                                  : "#",
                                                                                                              limit: item
                                                                                                                .opt
                                                                                                                .limit
                                                                                                                ? item
                                                                                                                    .opt
                                                                                                                    .limit
                                                                                                                : 6,
                                                                                                              "file-list":
                                                                                                                _vm
                                                                                                                  .form[
                                                                                                                  item
                                                                                                                    .key
                                                                                                                ],
                                                                                                              accept:
                                                                                                                item.opt &&
                                                                                                                item
                                                                                                                  .opt
                                                                                                                  .accept
                                                                                                                  ? item
                                                                                                                      .opt
                                                                                                                      .accept
                                                                                                                  : "",
                                                                                                              multiple: item
                                                                                                                .opt
                                                                                                                .multiple
                                                                                                                ? _vm.multiple
                                                                                                                : false,
                                                                                                              "on-change": _vm.onChangeFile(
                                                                                                                item
                                                                                                              ),
                                                                                                              "before-upload":
                                                                                                                item
                                                                                                                  .opt
                                                                                                                  .beforeUploadFile ||
                                                                                                                function() {
                                                                                                                  return true
                                                                                                                },
                                                                                                              "on-success": _vm.onSuccessByFormUploadFile(
                                                                                                                item
                                                                                                              ),
                                                                                                              "on-exceed":
                                                                                                                _vm.onExceed,
                                                                                                              "on-remove": _vm.onHandleRemove(
                                                                                                                item
                                                                                                              ),
                                                                                                              "before-remove":
                                                                                                                item
                                                                                                                  .opt
                                                                                                                  .remove,
                                                                                                              "on-progress":
                                                                                                                item
                                                                                                                  .opt
                                                                                                                  .onProgress
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "el-button",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  size:
                                                                                                                    "small",
                                                                                                                  type:
                                                                                                                    "primary"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "点击上传"
                                                                                                                )
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "el-upload__tip",
                                                                                                                attrs: {
                                                                                                                  slot:
                                                                                                                    "tip"
                                                                                                                },
                                                                                                                slot:
                                                                                                                  "tip"
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  _vm._s(
                                                                                                                    item
                                                                                                                      .opt
                                                                                                                      .tip
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      : item.type ==
                                                                                                        "img-list"
                                                                                                        ? _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "img-list"
                                                                                                            },
                                                                                                            _vm._l(
                                                                                                              _vm
                                                                                                                .form[
                                                                                                                item
                                                                                                                  .key
                                                                                                              ],
                                                                                                              function(
                                                                                                                it
                                                                                                              ) {
                                                                                                                return _c(
                                                                                                                  "img",
                                                                                                                  {
                                                                                                                    key:
                                                                                                                      it.src,
                                                                                                                    attrs: {
                                                                                                                      src:
                                                                                                                        it.src
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        _vm.showBig(
                                                                                                                          it.src
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                )
                                                                                                              }
                                                                                                            ),
                                                                                                            0
                                                                                                          )
                                                                                                        : item.type ==
                                                                                                          "img"
                                                                                                          ? _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "img"
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "img",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      src:
                                                                                                                        _vm
                                                                                                                          .form[
                                                                                                                          item
                                                                                                                            .key
                                                                                                                        ]
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        _vm.showBig(
                                                                                                                          _vm
                                                                                                                            .form[
                                                                                                                            item
                                                                                                                              .key
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          : item.type ==
                                                                                                            "table"
                                                                                                            ? _c(
                                                                                                                "div",
                                                                                                                {
                                                                                                                  staticClass:
                                                                                                                    "table"
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "el-row",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "table__row table__header"
                                                                                                                    },
                                                                                                                    _vm._l(
                                                                                                                      item
                                                                                                                        .opt
                                                                                                                        .columns,
                                                                                                                      function(
                                                                                                                        it
                                                                                                                      ) {
                                                                                                                        return _c(
                                                                                                                          "el-col",
                                                                                                                          {
                                                                                                                            key:
                                                                                                                              it.key
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              _vm._s(
                                                                                                                                it.label
                                                                                                                              )
                                                                                                                            )
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      }
                                                                                                                    ),
                                                                                                                    1
                                                                                                                  ),
                                                                                                                  _vm.type !=
                                                                                                                    "show" &&
                                                                                                                  !item
                                                                                                                    .opt
                                                                                                                    .isDisabled &&
                                                                                                                  !_vm
                                                                                                                    .form[
                                                                                                                    item
                                                                                                                      .key
                                                                                                                  ]
                                                                                                                    .length
                                                                                                                    ? _c(
                                                                                                                        "i",
                                                                                                                        {
                                                                                                                          staticClass:
                                                                                                                            "not_table__action el-icon-circle-plus",
                                                                                                                          on: {
                                                                                                                            click: function(
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              _vm.form[
                                                                                                                                item
                                                                                                                                  .key
                                                                                                                              ].push(
                                                                                                                                JSON.parse(
                                                                                                                                  JSON.stringify(
                                                                                                                                    item
                                                                                                                                      .opt
                                                                                                                                      .temp
                                                                                                                                  )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                  _vm._l(
                                                                                                                    _vm
                                                                                                                      .form[
                                                                                                                      item
                                                                                                                        .key
                                                                                                                    ],
                                                                                                                    function(
                                                                                                                      it,
                                                                                                                      index
                                                                                                                    ) {
                                                                                                                      return _c(
                                                                                                                        "el-form",
                                                                                                                        {
                                                                                                                          key: index,
                                                                                                                          ref:
                                                                                                                            "table_form",
                                                                                                                          refInFor: true,
                                                                                                                          attrs: {
                                                                                                                            model:
                                                                                                                              _vm
                                                                                                                                .form[
                                                                                                                                item
                                                                                                                                  .key
                                                                                                                              ][
                                                                                                                                index
                                                                                                                              ],
                                                                                                                            rules:
                                                                                                                              item.rules
                                                                                                                          }
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "el-row",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "table__row"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._l(
                                                                                                                                item
                                                                                                                                  .opt
                                                                                                                                  .columns,
                                                                                                                                function(
                                                                                                                                  i,
                                                                                                                                  k
                                                                                                                                ) {
                                                                                                                                  return _c(
                                                                                                                                    "el-col",
                                                                                                                                    {
                                                                                                                                      key: k
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _c(
                                                                                                                                        "el-form-item",
                                                                                                                                        {
                                                                                                                                          attrs: {
                                                                                                                                            prop:
                                                                                                                                              i.key
                                                                                                                                          }
                                                                                                                                        },
                                                                                                                                        [
                                                                                                                                          !i.type
                                                                                                                                            ? _c(
                                                                                                                                                "input",
                                                                                                                                                {
                                                                                                                                                  directives: [
                                                                                                                                                    {
                                                                                                                                                      name:
                                                                                                                                                        "model",
                                                                                                                                                      rawName:
                                                                                                                                                        "v-model",
                                                                                                                                                      value:
                                                                                                                                                        _vm
                                                                                                                                                          .form[
                                                                                                                                                          item
                                                                                                                                                            .key
                                                                                                                                                        ][
                                                                                                                                                          index
                                                                                                                                                        ][
                                                                                                                                                          i
                                                                                                                                                            .key
                                                                                                                                                        ],
                                                                                                                                                      expression:
                                                                                                                                                        "form[item.key][index][i.key]"
                                                                                                                                                    }
                                                                                                                                                  ],
                                                                                                                                                  attrs: {
                                                                                                                                                    type:
                                                                                                                                                      "text",
                                                                                                                                                    readonly:
                                                                                                                                                      item.opt &&
                                                                                                                                                      item
                                                                                                                                                        .opt
                                                                                                                                                        .isDisabled,
                                                                                                                                                    disabled:
                                                                                                                                                      _vm.type !=
                                                                                                                                                      "show"
                                                                                                                                                        ? false
                                                                                                                                                        : true,
                                                                                                                                                    clearable:
                                                                                                                                                      ""
                                                                                                                                                  },
                                                                                                                                                  domProps: {
                                                                                                                                                    value:
                                                                                                                                                      _vm
                                                                                                                                                        .form[
                                                                                                                                                        item
                                                                                                                                                          .key
                                                                                                                                                      ][
                                                                                                                                                        index
                                                                                                                                                      ][
                                                                                                                                                        i
                                                                                                                                                          .key
                                                                                                                                                      ]
                                                                                                                                                  },
                                                                                                                                                  on: {
                                                                                                                                                    input: function(
                                                                                                                                                      $event
                                                                                                                                                    ) {
                                                                                                                                                      if (
                                                                                                                                                        $event
                                                                                                                                                          .target
                                                                                                                                                          .composing
                                                                                                                                                      ) {
                                                                                                                                                        return
                                                                                                                                                      }
                                                                                                                                                      _vm.$set(
                                                                                                                                                        _vm
                                                                                                                                                          .form[
                                                                                                                                                          item
                                                                                                                                                            .key
                                                                                                                                                        ][
                                                                                                                                                          index
                                                                                                                                                        ],
                                                                                                                                                        i.key,
                                                                                                                                                        $event
                                                                                                                                                          .target
                                                                                                                                                          .value
                                                                                                                                                      )
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              )
                                                                                                                                            : i.type ==
                                                                                                                                              "number"
                                                                                                                                              ? _c(
                                                                                                                                                  "input",
                                                                                                                                                  {
                                                                                                                                                    directives: [
                                                                                                                                                      {
                                                                                                                                                        name:
                                                                                                                                                          "model",
                                                                                                                                                        rawName:
                                                                                                                                                          "v-model.number",
                                                                                                                                                        value:
                                                                                                                                                          _vm
                                                                                                                                                            .form[
                                                                                                                                                            item
                                                                                                                                                              .key
                                                                                                                                                          ][
                                                                                                                                                            index
                                                                                                                                                          ][
                                                                                                                                                            i
                                                                                                                                                              .key
                                                                                                                                                          ],
                                                                                                                                                        expression:
                                                                                                                                                          "form[item.key][index][i.key]",
                                                                                                                                                        modifiers: {
                                                                                                                                                          number: true
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    ],
                                                                                                                                                    attrs: {
                                                                                                                                                      type:
                                                                                                                                                        "number",
                                                                                                                                                      max:
                                                                                                                                                        i.max,
                                                                                                                                                      readonly:
                                                                                                                                                        item.opt &&
                                                                                                                                                        item
                                                                                                                                                          .opt
                                                                                                                                                          .isDisabled,
                                                                                                                                                      disabled:
                                                                                                                                                        _vm.type !=
                                                                                                                                                        "show"
                                                                                                                                                          ? false
                                                                                                                                                          : true,
                                                                                                                                                      clearable:
                                                                                                                                                        ""
                                                                                                                                                    },
                                                                                                                                                    domProps: {
                                                                                                                                                      value:
                                                                                                                                                        _vm
                                                                                                                                                          .form[
                                                                                                                                                          item
                                                                                                                                                            .key
                                                                                                                                                        ][
                                                                                                                                                          index
                                                                                                                                                        ][
                                                                                                                                                          i
                                                                                                                                                            .key
                                                                                                                                                        ]
                                                                                                                                                    },
                                                                                                                                                    on: {
                                                                                                                                                      input: function(
                                                                                                                                                        $event
                                                                                                                                                      ) {
                                                                                                                                                        if (
                                                                                                                                                          $event
                                                                                                                                                            .target
                                                                                                                                                            .composing
                                                                                                                                                        ) {
                                                                                                                                                          return
                                                                                                                                                        }
                                                                                                                                                        _vm.$set(
                                                                                                                                                          _vm
                                                                                                                                                            .form[
                                                                                                                                                            item
                                                                                                                                                              .key
                                                                                                                                                          ][
                                                                                                                                                            index
                                                                                                                                                          ],
                                                                                                                                                          i.key,
                                                                                                                                                          _vm._n(
                                                                                                                                                            $event
                                                                                                                                                              .target
                                                                                                                                                              .value
                                                                                                                                                          )
                                                                                                                                                        )
                                                                                                                                                      },
                                                                                                                                                      blur: function(
                                                                                                                                                        $event
                                                                                                                                                      ) {
                                                                                                                                                        _vm.$forceUpdate()
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                )
                                                                                                                                              : i.type ==
                                                                                                                                                "number-pro"
                                                                                                                                                ? _c(
                                                                                                                                                    "el-input-number",
                                                                                                                                                    {
                                                                                                                                                      attrs: {
                                                                                                                                                        max: i.max
                                                                                                                                                          ? i.max
                                                                                                                                                          : "Infinity",
                                                                                                                                                        min:
                                                                                                                                                          i.min !=
                                                                                                                                                          undefined
                                                                                                                                                            ? i.min
                                                                                                                                                            : "-Infinity",
                                                                                                                                                        disabled:
                                                                                                                                                          _vm.type !=
                                                                                                                                                          "show"
                                                                                                                                                            ? false
                                                                                                                                                            : true,
                                                                                                                                                        controls: false
                                                                                                                                                      },
                                                                                                                                                      model: {
                                                                                                                                                        value:
                                                                                                                                                          _vm
                                                                                                                                                            .form[
                                                                                                                                                            item
                                                                                                                                                              .key
                                                                                                                                                          ][
                                                                                                                                                            index
                                                                                                                                                          ][
                                                                                                                                                            i
                                                                                                                                                              .key
                                                                                                                                                          ],
                                                                                                                                                        callback: function(
                                                                                                                                                          $$v
                                                                                                                                                        ) {
                                                                                                                                                          _vm.$set(
                                                                                                                                                            _vm
                                                                                                                                                              .form[
                                                                                                                                                              item
                                                                                                                                                                .key
                                                                                                                                                            ][
                                                                                                                                                              index
                                                                                                                                                            ],
                                                                                                                                                            i.key,
                                                                                                                                                            $$v
                                                                                                                                                          )
                                                                                                                                                        },
                                                                                                                                                        expression:
                                                                                                                                                          "form[item.key][index][i.key]"
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  )
                                                                                                                                                : _vm._e()
                                                                                                                                        ],
                                                                                                                                        1
                                                                                                                                      )
                                                                                                                                    ],
                                                                                                                                    1
                                                                                                                                  )
                                                                                                                                }
                                                                                                                              ),
                                                                                                                              _vm.type !=
                                                                                                                                "show" &&
                                                                                                                              !item
                                                                                                                                .opt
                                                                                                                                .isDisabled
                                                                                                                                ? _c(
                                                                                                                                    "i",
                                                                                                                                    {
                                                                                                                                      staticClass:
                                                                                                                                        "table__action el-icon-remove",
                                                                                                                                      on: {
                                                                                                                                        click: function(
                                                                                                                                          $event
                                                                                                                                        ) {
                                                                                                                                          _vm.form[
                                                                                                                                            item
                                                                                                                                              .key
                                                                                                                                          ].splice(
                                                                                                                                            index,
                                                                                                                                            1
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  )
                                                                                                                                : _vm._e(),
                                                                                                                              _vm.type !=
                                                                                                                                "show" &&
                                                                                                                              !item
                                                                                                                                .opt
                                                                                                                                .isDisabled &&
                                                                                                                              index ==
                                                                                                                                _vm
                                                                                                                                  .form[
                                                                                                                                  item
                                                                                                                                    .key
                                                                                                                                ]
                                                                                                                                  .length -
                                                                                                                                  1
                                                                                                                                ? _c(
                                                                                                                                    "i",
                                                                                                                                    {
                                                                                                                                      staticClass:
                                                                                                                                        "table__action el-icon-circle-plus",
                                                                                                                                      on: {
                                                                                                                                        click: function(
                                                                                                                                          $event
                                                                                                                                        ) {
                                                                                                                                          _vm.form[
                                                                                                                                            item
                                                                                                                                              .key
                                                                                                                                          ].push(
                                                                                                                                            JSON.parse(
                                                                                                                                              JSON.stringify(
                                                                                                                                                item
                                                                                                                                                  .opt
                                                                                                                                                  .temp
                                                                                                                                              )
                                                                                                                                            )
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  )
                                                                                                                                : _vm._e()
                                                                                                                            ],
                                                                                                                            2
                                                                                                                          )
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      )
                                                                                                                    }
                                                                                                                  )
                                                                                                                ],
                                                                                                                2
                                                                                                              )
                                                                                                            : item.type ==
                                                                                                              "slot"
                                                                                                              ? _c(
                                                                                                                  "div",
                                                                                                                  {},
                                                                                                                  [
                                                                                                                    _vm._t(
                                                                                                                      item
                                                                                                                        .opt
                                                                                                                        .slotName
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  2
                                                                                                                )
                                                                                                              : item.type ==
                                                                                                                "radio"
                                                                                                                ? _c(
                                                                                                                    "div",
                                                                                                                    {},
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "el-radio-group",
                                                                                                                        {
                                                                                                                          model: {
                                                                                                                            value:
                                                                                                                              _vm
                                                                                                                                .form[
                                                                                                                                item
                                                                                                                                  .key
                                                                                                                              ],
                                                                                                                            callback: function(
                                                                                                                              $$v
                                                                                                                            ) {
                                                                                                                              _vm.$set(
                                                                                                                                _vm.form,
                                                                                                                                item.key,
                                                                                                                                $$v
                                                                                                                              )
                                                                                                                            },
                                                                                                                            expression:
                                                                                                                              "form[item.key]"
                                                                                                                          }
                                                                                                                        },
                                                                                                                        _vm._l(
                                                                                                                          item
                                                                                                                            .opt
                                                                                                                            .list,
                                                                                                                          function(
                                                                                                                            items,
                                                                                                                            indexs
                                                                                                                          ) {
                                                                                                                            return _c(
                                                                                                                              "el-radio",
                                                                                                                              {
                                                                                                                                key: indexs,
                                                                                                                                attrs: {
                                                                                                                                  disabled: items.disabled
                                                                                                                                    ? true
                                                                                                                                    : false,
                                                                                                                                  label:
                                                                                                                                    items.id
                                                                                                                                }
                                                                                                                              },
                                                                                                                              [
                                                                                                                                _vm._v(
                                                                                                                                  _vm._s(
                                                                                                                                    items.label
                                                                                                                                  ) +
                                                                                                                                    "\n            "
                                                                                                                                )
                                                                                                                              ]
                                                                                                                            )
                                                                                                                          }
                                                                                                                        ),
                                                                                                                        1
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                : item.type ==
                                                                                                                  "radioHasText"
                                                                                                                  ? _c(
                                                                                                                      "div",
                                                                                                                      {},
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "el-radio-group",
                                                                                                                          {
                                                                                                                            model: {
                                                                                                                              value:
                                                                                                                                _vm
                                                                                                                                  .form[
                                                                                                                                  item
                                                                                                                                    .key
                                                                                                                                ],
                                                                                                                              callback: function(
                                                                                                                                $$v
                                                                                                                              ) {
                                                                                                                                _vm.$set(
                                                                                                                                  _vm.form,
                                                                                                                                  item.key,
                                                                                                                                  $$v
                                                                                                                                )
                                                                                                                              },
                                                                                                                              expression:
                                                                                                                                "form[item.key]"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          _vm._l(
                                                                                                                            item
                                                                                                                              .opt
                                                                                                                              .list,
                                                                                                                            function(
                                                                                                                              items,
                                                                                                                              indexs
                                                                                                                            ) {
                                                                                                                              return _c(
                                                                                                                                "el-radio",
                                                                                                                                {
                                                                                                                                  key: indexs,
                                                                                                                                  staticStyle: {
                                                                                                                                    "padding-top":
                                                                                                                                      "14px"
                                                                                                                                  },
                                                                                                                                  attrs: {
                                                                                                                                    disabled: items.disabled
                                                                                                                                      ? true
                                                                                                                                      : false,
                                                                                                                                    label:
                                                                                                                                      items.id
                                                                                                                                  }
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "span",
                                                                                                                                    [
                                                                                                                                      _vm._v(
                                                                                                                                        _vm._s(
                                                                                                                                          items.label
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    ]
                                                                                                                                  ),
                                                                                                                                  _c(
                                                                                                                                    "div",
                                                                                                                                    {
                                                                                                                                      staticStyle: {
                                                                                                                                        color:
                                                                                                                                          "#909399",
                                                                                                                                        "padding-top":
                                                                                                                                          "6px",
                                                                                                                                        "foxt-size":
                                                                                                                                          "13px",
                                                                                                                                        "padding-left":
                                                                                                                                          "25px"
                                                                                                                                      }
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _vm._v(
                                                                                                                                        _vm._s(
                                                                                                                                          items.text
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    ]
                                                                                                                                  )
                                                                                                                                ]
                                                                                                                              )
                                                                                                                            }
                                                                                                                          ),
                                                                                                                          1
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  : item.type ==
                                                                                                                    "tel"
                                                                                                                    ? _c(
                                                                                                                        "el-input",
                                                                                                                        {
                                                                                                                          attrs: {
                                                                                                                            disabled: item.disabled
                                                                                                                              ? true
                                                                                                                              : false,
                                                                                                                            clearable:
                                                                                                                              "",
                                                                                                                            placeholder:
                                                                                                                              _vm.type !=
                                                                                                                              "show"
                                                                                                                                ? item.placeholder
                                                                                                                                  ? item.placeholder
                                                                                                                                  : "请填写" +
                                                                                                                                    item.label
                                                                                                                                : ""
                                                                                                                          },
                                                                                                                          model: {
                                                                                                                            value:
                                                                                                                              _vm
                                                                                                                                .form[
                                                                                                                                item
                                                                                                                                  .key
                                                                                                                              ],
                                                                                                                            callback: function(
                                                                                                                              $$v
                                                                                                                            ) {
                                                                                                                              _vm.$set(
                                                                                                                                _vm.form,
                                                                                                                                item.key,
                                                                                                                                $$v
                                                                                                                              )
                                                                                                                            },
                                                                                                                            expression:
                                                                                                                              "form[item.key]"
                                                                                                                          }
                                                                                                                        }
                                                                                                                      )
                                                                                                                    : item.type ==
                                                                                                                      "datetimerange-list"
                                                                                                                      ? _c(
                                                                                                                          "div",
                                                                                                                          [
                                                                                                                            _vm._l(
                                                                                                                              _vm
                                                                                                                                .form[
                                                                                                                                item
                                                                                                                                  .key
                                                                                                                              ],
                                                                                                                              function(
                                                                                                                                it,
                                                                                                                                idx
                                                                                                                              ) {
                                                                                                                                return _c(
                                                                                                                                  "div",
                                                                                                                                  {
                                                                                                                                    key: idx,
                                                                                                                                    staticClass:
                                                                                                                                      "datetimerange-list-box"
                                                                                                                                  },
                                                                                                                                  [
                                                                                                                                    _c(
                                                                                                                                      "el-date-picker",
                                                                                                                                      {
                                                                                                                                        attrs: {
                                                                                                                                          type: item.rangeType
                                                                                                                                            ? item.rangeType
                                                                                                                                            : "daterange",
                                                                                                                                          "picker-options": {
                                                                                                                                            disabledDate: _vm.onDatetimerangeDisabledDate(
                                                                                                                                              item
                                                                                                                                            )
                                                                                                                                          },
                                                                                                                                          "range-separator":
                                                                                                                                            "至",
                                                                                                                                          "start-placeholder":
                                                                                                                                            "开始日期",
                                                                                                                                          "end-placeholder":
                                                                                                                                            "结束日期"
                                                                                                                                        },
                                                                                                                                        model: {
                                                                                                                                          value:
                                                                                                                                            _vm
                                                                                                                                              .form[
                                                                                                                                              item
                                                                                                                                                .key
                                                                                                                                            ][
                                                                                                                                              idx
                                                                                                                                            ],
                                                                                                                                          callback: function(
                                                                                                                                            $$v
                                                                                                                                          ) {
                                                                                                                                            _vm.$set(
                                                                                                                                              _vm
                                                                                                                                                .form[
                                                                                                                                                item
                                                                                                                                                  .key
                                                                                                                                              ],
                                                                                                                                              idx,
                                                                                                                                              $$v
                                                                                                                                            )
                                                                                                                                          },
                                                                                                                                          expression:
                                                                                                                                            "form[item.key][idx]"
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    ),
                                                                                                                                    _vm.type !=
                                                                                                                                    "show"
                                                                                                                                      ? _c(
                                                                                                                                          "el-button",
                                                                                                                                          {
                                                                                                                                            staticClass:
                                                                                                                                              "daterange-list__del",
                                                                                                                                            attrs: {
                                                                                                                                              type:
                                                                                                                                                "danger",
                                                                                                                                              icon:
                                                                                                                                                "el-icon-delete",
                                                                                                                                              circle:
                                                                                                                                                ""
                                                                                                                                            },
                                                                                                                                            on: {
                                                                                                                                              click: function(
                                                                                                                                                $event
                                                                                                                                              ) {
                                                                                                                                                _vm.onDelDateRangeList(
                                                                                                                                                  item,
                                                                                                                                                  _vm
                                                                                                                                                    .form[
                                                                                                                                                    item
                                                                                                                                                      .key
                                                                                                                                                  ],
                                                                                                                                                  idx
                                                                                                                                                )
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        )
                                                                                                                                      : _vm._e()
                                                                                                                                  ],
                                                                                                                                  1
                                                                                                                                )
                                                                                                                              }
                                                                                                                            ),
                                                                                                                            _vm.type !=
                                                                                                                            "show"
                                                                                                                              ? _c(
                                                                                                                                  "el-button",
                                                                                                                                  {
                                                                                                                                    staticClass:
                                                                                                                                      "daterange-list__add",
                                                                                                                                    attrs: {
                                                                                                                                      round:
                                                                                                                                        ""
                                                                                                                                    },
                                                                                                                                    on: {
                                                                                                                                      click: function(
                                                                                                                                        $event
                                                                                                                                      ) {
                                                                                                                                        _vm.onAddDateRangeList(
                                                                                                                                          item,
                                                                                                                                          _vm
                                                                                                                                            .form[
                                                                                                                                            item
                                                                                                                                              .key
                                                                                                                                          ]
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  },
                                                                                                                                  [
                                                                                                                                    _vm._v(
                                                                                                                                      "增加"
                                                                                                                                    )
                                                                                                                                  ]
                                                                                                                                )
                                                                                                                              : _vm._e(),
                                                                                                                            item
                                                                                                                              .opt
                                                                                                                              .length
                                                                                                                              ? _c(
                                                                                                                                  "div",
                                                                                                                                  {
                                                                                                                                    staticClass:
                                                                                                                                      "datetimerange-list-alt"
                                                                                                                                  },
                                                                                                                                  [
                                                                                                                                    _vm._v(
                                                                                                                                      "最多添加" +
                                                                                                                                        _vm._s(
                                                                                                                                          item
                                                                                                                                            .opt
                                                                                                                                            .length
                                                                                                                                        ) +
                                                                                                                                        "个"
                                                                                                                                    )
                                                                                                                                  ]
                                                                                                                                )
                                                                                                                              : _vm._e()
                                                                                                                          ],
                                                                                                                          2
                                                                                                                        )
                                                                                                                      : item.type ==
                                                                                                                        "weekly-range"
                                                                                                                        ? _c(
                                                                                                                            "div",
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "el-checkbox-group",
                                                                                                                                {
                                                                                                                                  model: {
                                                                                                                                    value:
                                                                                                                                      _vm
                                                                                                                                        .form[
                                                                                                                                        item
                                                                                                                                          .key
                                                                                                                                      ],
                                                                                                                                    callback: function(
                                                                                                                                      $$v
                                                                                                                                    ) {
                                                                                                                                      _vm.$set(
                                                                                                                                        _vm.form,
                                                                                                                                        item.key,
                                                                                                                                        $$v
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                    expression:
                                                                                                                                      "form[item.key]"
                                                                                                                                  }
                                                                                                                                },
                                                                                                                                _vm._l(
                                                                                                                                  _vm.weekly,
                                                                                                                                  function(
                                                                                                                                    it
                                                                                                                                  ) {
                                                                                                                                    return _c(
                                                                                                                                      "el-checkbox",
                                                                                                                                      {
                                                                                                                                        key: it,
                                                                                                                                        attrs: {
                                                                                                                                          label: it,
                                                                                                                                          border:
                                                                                                                                            ""
                                                                                                                                        }
                                                                                                                                      },
                                                                                                                                      [
                                                                                                                                        _vm._v(
                                                                                                                                          _vm._s(
                                                                                                                                            _vm
                                                                                                                                              .weekConfig[
                                                                                                                                              it
                                                                                                                                            ]
                                                                                                                                          )
                                                                                                                                        )
                                                                                                                                      ]
                                                                                                                                    )
                                                                                                                                  }
                                                                                                                                ),
                                                                                                                                1
                                                                                                                              ),
                                                                                                                              item.opt &&
                                                                                                                              item
                                                                                                                                .opt
                                                                                                                                .alt
                                                                                                                                ? _c(
                                                                                                                                    "div",
                                                                                                                                    {
                                                                                                                                      staticClass:
                                                                                                                                        "weekly-range-alt"
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _vm._v(
                                                                                                                                        _vm._s(
                                                                                                                                          item
                                                                                                                                            .opt
                                                                                                                                            .alt
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    ]
                                                                                                                                  )
                                                                                                                                : _vm._e()
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          )
                                                                                                                        : item.type ==
                                                                                                                          "monthly-range"
                                                                                                                          ? _c(
                                                                                                                              "div",
                                                                                                                              {
                                                                                                                                staticClass:
                                                                                                                                  "monthly-range"
                                                                                                                              },
                                                                                                                              [
                                                                                                                                _c(
                                                                                                                                  "div",
                                                                                                                                  {
                                                                                                                                    staticClass:
                                                                                                                                      "monthly-range-box"
                                                                                                                                  },
                                                                                                                                  _vm._l(
                                                                                                                                    _vm.monthly,
                                                                                                                                    function(
                                                                                                                                      it,
                                                                                                                                      idx
                                                                                                                                    ) {
                                                                                                                                      return _c(
                                                                                                                                        "div",
                                                                                                                                        {
                                                                                                                                          key: idx,
                                                                                                                                          staticClass:
                                                                                                                                            "monthly-range-but",
                                                                                                                                          class: {
                                                                                                                                            "monthly-range-but_act":
                                                                                                                                              it.isAct
                                                                                                                                          },
                                                                                                                                          on: {
                                                                                                                                            click: function(
                                                                                                                                              $event
                                                                                                                                            ) {
                                                                                                                                              _vm.onMonthlyRange(
                                                                                                                                                it,
                                                                                                                                                idx,
                                                                                                                                                item
                                                                                                                                              )
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        },
                                                                                                                                        [
                                                                                                                                          _vm._v(
                                                                                                                                            _vm._s(
                                                                                                                                              it.idx
                                                                                                                                            )
                                                                                                                                          )
                                                                                                                                        ]
                                                                                                                                      )
                                                                                                                                    }
                                                                                                                                  ),
                                                                                                                                  0
                                                                                                                                ),
                                                                                                                                item.opt &&
                                                                                                                                item
                                                                                                                                  .opt
                                                                                                                                  .alt
                                                                                                                                  ? _c(
                                                                                                                                      "div",
                                                                                                                                      {
                                                                                                                                        staticClass:
                                                                                                                                          "monthly-range-alt"
                                                                                                                                      },
                                                                                                                                      [
                                                                                                                                        _vm._v(
                                                                                                                                          _vm._s(
                                                                                                                                            item
                                                                                                                                              .opt
                                                                                                                                              .alt
                                                                                                                                          )
                                                                                                                                        )
                                                                                                                                      ]
                                                                                                                                    )
                                                                                                                                  : _vm._e()
                                                                                                                              ]
                                                                                                                            )
                                                                                                                          : _vm._e()
                      ],
                      1
                    )
            ],
            1
          )
        }),
        0
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }