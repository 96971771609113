import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('./views/Login.vue')
        },
        {
            path: '/login',
            component: () => import('./views/Login.vue')
        },
        {
            path: '/downLoad',
            component: () => import('./views/downLoad.vue')
        },
        {
            path: '/secondCheck',
            component: () => import('./views/secondCheck.vue')
        },
        // 商家、供应商、经销商入驻页面
        {
            path: '/occupancy/:type',
            component: () => import('./views/occupancy.vue')
        },
        {
            path: '/main',
            component: () => import('./views/Main.vue'),
            children: [
                {
                    path: 'log/list',
                    component: () => import('./views/log/list.vue'),
                },
                {
                    path: 'parameter/list',
                    component: () => import('./views/parameter/list.vue'),
                },
                {
                    path: 'parameter/detail/:type/:id',
                    component: () => import('./views/parameter/detail.vue'),
                },
                {
                    path: 'adManagement/list',
                    component: () => import('./views/adManagement/list.vue'),
                },
                {
                    path: 'adManagement/detail/:type/:id',
                    component: () => import('./views/adManagement/detail.vue'),
                },
                {
                    path: 'adSpaceManagement/list',
                    component: () => import('./views/adSpaceManagement/list.vue'),
                },
                {
                    path: 'user/list',
                    component: () => import('./views/user/list.vue'),
                    meta: {
                        btnId: {
                            '41': 0,
                            '42': 1,
                            '43': 2
                        }
                    }
                },
                // 房源配置
                {
                    path: 'apartment/config',
                    component: () => import('./views/apartment/config.vue'),
                },
                {
                    path: 'exportCenter/list',
                    component: () => import('./views/exportCenter/list.vue'),
                },
                {
                    path: 'adSpaceManagement/adConfiguration/:id',
                    component: () => import('./views/adSpaceManagement/adConfiguration.vue'),
                },
                {
                    path: 'user/engineering/:type/:id',
                    component: () => import('./views/user/engineering.vue')
                },
                {
                    path: 'user/authorized',
                    component: () => import('./views/user/authorized.vue')
                },
                {
                    path: 'user/selectedContainer',
                    component: () => import('./views/user/selectedContainer.vue')
                },
                {
                    path: 'role/list',
                    component: () => import('./views/role/list.vue'),
                    meta: {
                        btnId: {
                            '44': 0,
                            '45': 1,
                            '46': 2
                        }
                    }
                },
                {
                    path: 'date/list',
                    component: () => import('./views/date/list.vue'),
                },
                {
                    path: 'record/list',
                    component: () => import('./views/record/list.vue'),
                },
                {
                    path: 'record/detail/:type/:id',
                    component: () => import('./views/record/detail.vue'),
                },
                {
                    path: 'money/list',
                    component: () => import('./views/money/list.vue'),
                },
                {
                    path: 'money/detail/:type/:id',
                    component: () => import('./views/money/detail.vue')
                },
                {
                    path: 'money/zhuan/:type/:id',
                    component: () => import('./views/money/zhuan.vue'),
                },
                {
                    path: 'role/detail/:type/:id',
                    component: () => import('./views/role/detail.vue'),
                },
                {
                    path: 'menu/list',
                    component: () => import('./views/menu/list.vue'),
                },
                {
                    path: 'menu/detail/:type/:id',
                    component: () => import('./views/menu/detail.vue'),
                },
                {
                    path: 'dataOverview/list',
                    component: () => import('./views/dataOverview/list.vue'),
                },
                // 房东-数据概览-首页
                {
                    path: 'landlorDataOverview/list',
                    component: () => import('./views/landlorDataOverview/list.vue'),
                },
                {
                    path: 'dataUser/list',
                    component: () => import('./views/dataUser/list.vue'),
                },
                {
                    path: 'dataRoom/list',
                    component: () => import('./views/dataRoom/list.vue'),
                },
                {
                    path: 'dataDevice/list',
                    component: () => import('./views/dataDevice/list.vue'),
                },
                {
                    path: 'dataShop/list',
                    component: () => import('./views/dataShop/list.vue'),
                },
                {
                    path: 'apartment/list',
                    component: () => import('./views/apartment/list.vue'),
                    meta: {
                        btnId: {
                            '28': 0,
                            '29': 1,
                            '72': 2
                        }
                    }
                },
                {
                    path: 'apartment/apartmentDetail/:type/:id',
                    component: () => import('./views/apartment/apartmentDetail.vue'),
                },
                // 根据房源配置来动态生成的房源配置页面
                {
                    path: 'apartment/dyn-config-apartmentDetail/:type/:id',
                    component: () => import('./views/apartment/dyn-config-apartmentDetail.vue'),
                },
                {
                    path: 'house/list',
                    component: () => import('./views/house/list.vue'),
                    meta: {
                        btnId: {
                            '31': 0,
                            '32': 1,
                            '73': 2
                        }
                    }
                },
                {
                    path: 'house/detail/:type/:id',
                    component: () => import('./views/house/detail.vue'),
                },
                {
                    path: 'room/list',
                    component: () => import('./views/room/list.vue'),
                    meta: {
                        btnId: {
                            '34': 0,
                            '35': 1,
                            '74': 2
                        }
                    }
                }, 
                {
                    path: 'landlordApartment/config',
                    component: () => import('./views/landlordApartment/config.vue'),
                },
                {
                    path: 'CheckOut/list',
                    component: () => import('./views/CheckOut/list.vue'),
                    meta: {
                        btnId: {
                            '34': 0,
                            '35': 1,
                            '36': 2
                        }
                    }
                },
                {
                    path: 'userList/list',
                    component: () => import('./views/userList/list.vue'),
                },
                {
                    path: 'room/detail/:type/:id',
                    component: () => import('./views/room/detail.vue'),
                },
                {
                    path: 'room/schoolDetail/:type/:id',
                    component: () => import('./views/room/schoolDetail.vue'),
                },
                {
                    path: 'room/batch/:type/:id',
                    component: () => import('./views/room/batch.vue'),
                },
                {
                    path: 'room/schoolBatch/:type/:id',
                    component: () => import('./views/room/schoolBatch.vue'),
                },
                {
                    path: 'room/Rowhouse/:type/:id',
                    component: () => import('./views/room/Rowhouse.vue'),
                },
                {
                    path: 'PassRecord/list',
                    component: () => import('./views/PassRecord/list.vue'),
                },
                {
                    path: 'PassRecord/detail/:type/:id',
                    component: () => import('./views/PassRecord/detail.vue'),
                },
                {
                    path: 'AccessControl/list',
                    component: () => import('./views/AccessControl/list.vue'),
                },
                {
                    path: 'AccessControl/detail/:type/:id',
                    component: () => import('./views/AccessControl/detail.vue'),
                },
                {
                    path: 'gateway/list',
                    component: () => import('./views/gateway/list.vue'),
                },
                {
                    path: 'gateway/detail/:id',
                    component: () => import('./views/gateway/detail.vue'),
                },
                {
                    path: 'auth-device/list',
                    component: () => import('./views/authDevice/list.vue'),
                },
                {
                    path: 'auth-device/detail/:type/:id',
                    component: () => import('./views/authDevice/detail.vue'),
                },
                {
                    path: 'lock/list',
                    component: () => import('./views/lock/list.vue'),
                },
                {
                    path: 'lock/log/:mac/:id/:type/:deviceId',
                    component: () => import('./views/lock/log.vue'),
                },
                {
                    path: 'lock/logs89/:mac/:id/:type',
                    component: () => import('./views/lock/logs89.vue'),
                },
                {
                    path: 'lock/cabinetLog/:mac/:lockTypeName/:id/:lockType',
                    component: () => import('./views/lock/cabinetLog.vue'),
                },
                {
                    path: 'lock/personLog/:deviceId/:model/:id',
                    component: () => import('./views/lock/personLog.vue'),
                },
                {
                    path: 'separateAccessControl/list',
                    component: () => import('./views/separateAccessControl/list.vue'),
                },
                {
                    path: 'separateAccessControl/log/:id',
                    component: () => import('./views/separateAccessControl/log.vue'),
                },
                {
                    path: 'separateAccessControl/personalDeviceDetail/:id',
                    component: () => import('./views/separateAccessControl/personalDeviceDetail.vue'),
                },
                {
                    path: 'BluetoothKey/list',
                    component: () => import('./views/BluetoothKey/list.vue'),
                      meta: {
                        btnId: {
                            '77': 1,
                        }
                    }
                },
                {
                    path: 'BluetoothKey/log/:id',
                    component: () => import('./views/BluetoothKey/log.vue'),
                },
                 {
                    path: 'electronicAccessControl/list',
                    component: () => import('./views/electronicAccessControl/list.vue'),
                    meta: {
                        btnId: {
                            '78': 1,
                        }
                    }
                },
                {
                    path: 'electronicAccessControl/log/:id',
                    component: () => import('./views/electronicAccessControl/log.vue'),
                },
                {
                    path: 'alarm/list',
                    component: () => import('./views/alarm/list.vue'),
                },
                {
                    path: 'alarm/detail/:mac',
                    component: () => import('./views/alarm/detail.vue'),
                },
                {
                    path: 'alarmRecord/list',
                    component: () => import('./views/alarmRecord/list.vue'),
                },
                 {
                    path: 'smartMeter/list',
                    component: () => import('./views/smartMeter/list.vue'),
                },
                {
                    path: 'smartMeter/log/:type/:id',
                    component: () => import('./views/smartMeter/log.vue'),
                },
                {
                    path: 'smartMeter/batch/:type/:id',
                    component: () => import('./views/smartMeter/batch.vue'),
                },
                  {
                    path: 'smartWaterMeter/list',
                    component: () => import('./views/smartWaterMeter/list.vue'),
                },
                {
                    path: 'smartWaterMeter/log/:id',
                    component: () => import('./views/smartWaterMeter/log.vue'),
                },
                {
                    path: 'order/list',
                    component: () => import('./views/order/list.vue')
                },
                {
                    path: 'order/detail/:type/:id',
                    component: () => import('./views/order/detail.vue')
                },
                {
                    path: 'Refundlist/list',
                    component: () => import('./views/Refundlist/list.vue')
                },
                {
                    path: 'Refundlist/detail/:type/:id',
                    component: () => import('./views/Refundlist/detail.vue')
                }, {
                    path: 'Refundlist/Reviewrefund/:type/:id',
                    component: () => import('./views/Refundlist/Reviewrefund.vue')
                },
                {
                    path: 'finance/overview',
                    component: () => import('./views/finance/overview.vue'),
                },
                {
                    path: 'sale/list',
                    component: () => import('./views/sale/list.vue'),
                },
                {
                    path: 'sale/detail/:type/:id',
                    component: () => import('./views/sale/detail.vue'),
                },
                {
                    path: 'statement/list',
                    component: () => import('./views/statement/list.vue'),
                },
                {
                    path: 'statement/detail/:type/:id',
                    component: () => import('./views/statement/detail.vue'),
                },
                {
                    path: 'statement/edit/:type/:id',
                    component: () => import('./views/statement/edit.vue'),
                },
                {
                    path: 'statement/order/:type/:id',
                    component: () => import('./views/statement/order.vue')
                },
                {
                    path: 'contract/list',
                    component: () => import('./views/contract/list.vue')
                },
                {
                    path: 'contract/detail/:type/:id',
                    component: () => import('./views/contract/detail.vue')
                },
                {
                    path: 'ContractTemplate/list',
                    component: () => import('./views/ContractTemplate/list.vue')
                },
                {
                    path: 'ContractTemplate/detail/:type/:id',
                    component: () => import('./views/ContractTemplate/detail.vue')
                },
                {
                    path: 'ContractTemplate/adddetail/:type/:id',
                    component: () => import('./views/ContractTemplate/adddetail.vue')
                },
                {
                    path: 'tenant/list',
                    component: () => import('./views/tenant/list.vue'),
                    meta: {
                        btnId: {
                            '40': 2,
                            '179': 1,
                        }
                    }
                },
                {
                    path: 'tenant/detail/:type/:id',
                    component: () => import('./views/tenant/detail.vue'),
                },
                {
                    path: 'tenant/hetong/:type/:id',
                    component: () => import('./views/tenant/hetong.vue'),
                },
                {
                    path: 'tenant/Apartment/:type/:id',
                    component: () => import('./views/tenant/Apartment.vue'),
                },
                {
                    path: 'tenant/CarInAndOut/:type/:id',
                    component: () => import('./views/tenant/CarInAndOut.vue'),
                },
                {
                    path: 'tenant/termination/:type',
                    component: () => import('./views/tenant/termination.vue'),
                },
                {
                    path: 'tenant/shortTermination/:type',
                    component: () => import('./views/tenant/shortTermination.vue'),
                },
                {
                    path: 'tenant/rowHousesTermination/:type',
                    component: () => import('./views/tenant/rowHousesTermination.vue'),
                },
                {
                    path: 'tenant/detailTab/:type/:id',
                    component: () => import('./views/tenant/detailTab.vue'),
                },
                {
                    path: 'authentication/list',
                    component: () => import('./views/authentication/list.vue'),
                },
                {
                    path: 'authentication/detail/:type/:id',
                    component: () => import('./views/authentication/detail.vue'),
                },
                {
                    path: 'actionLog/list',
                    component: () => import('./views/actionLog/list.vue'),
                },
                {
                    path: 'landlord/list',
                    component: () => import('./views/landlord/list.vue'),
                    meta: {
                        btnId: {
                            '47': 0,
                            '48': 1,
                            '49': 2
                        }
                    }
                },
                {
                    path: 'landlord/detail/:type/:id/:orgId',
                    component: () => import('./views/landlord/detail.vue'),
                },
                {
                    path: 'visit/list',
                    component: () => import('./views/visit/list.vue'),
                },
                {
                    path: 'visit/detail/:type/:id',
                    component: () => import('./views/visit/detail.vue'),
                },
                {
                    path: 'userSet/main',
                    component: () => import('./views/userSet/main.vue'),
                },
                {
                    path: 'userSet/password',
                    component: () => import('./views/userSet/password.vue'),
                },
                {
                    path: 'accountSecurity/detail',
                    component: () => import('./views/accountSecurity/detail.vue'),
                },
                {
                    path: 'account/list',
                    component: () => import('./views/account/list.vue'),
                    meta: {
                        btnId: {
                            '54': 0,
                            '55': 1,
                            '56': 2
                        }
                    }
                },
                {
                    path: 'account/detail/:type/:id',
                    component: () => import('./views/account/detail.vue'),
                },
                {
                    path: 'jurisdiction/list',
                    component: () => import('./views/jurisdiction/list.vue'),
                    meta: {
                        btnId: {
                            '57': 1,
                            '138': 0
                        }
                    }
                },
                {
                    path: 'guard/list',
                    component: () => import('./views/guard/list.vue'),
                },
                {
                    path: 'guard/detail',
                    component: () => import('./views/guard/detail.vue'),
                },
                {
                    path: 'information/detail/:type/:id',
                    component: () => import('./views/information/detail.vue'),
                },
                // 房东端 系统管理下的学校信息
                {
                    path: 'schoolInfo/list',
                    component: () => import('./views/schoolInfo/list.vue'),
                },
                {
                    path: 'ied/list',
                    component: () => import('./views/ied/list.vue'),
                },
                {
                    path: 'ied/detail/:type',
                    component: () => import('./views/ied/detail.vue'),
                },
                {
                    path: 'feedback/list',
                    component: () => import('./views/feedback/list.vue'),
                },
                {
                    path: 'CheckOut/detail/:type/:id',
                    component: () => import('./views/CheckOut/detail.vue'),
                },
                {
                    path: 'CheckOut/AddDetail/:type/:id',
                    component: () => import('./views/CheckOut/AddDetail.vue'),
                },
                 {
                    path: 'notice/list',
                    component: () => import('./views/notice/list.vue'),
                },
                {
                    path: 'membersManagement/list',
                    component: () => import('./views/membersManagement/list.vue'),
                },
                {
                    path: 'membersManagement/detail',
                    component: () => import('./views/membersManagement/detail.vue'),
                    meta: {
                        isNotKeep: true
                    }
                },
                {
                    path: 'cargoController/list',
                    component: () => import('./views/cargoController/list.vue'),
                },
                {
                    path: 'cargoController/detail/:type',
                    component: () => import('./views/cargoController/detail.vue'),
                }, 
                {
                    path: 'containerManagement/list',
                    component: () => import('./views/containerManagement/list.vue'),
                },
                {
                    path: 'containerManagement/detail/:type',
                    component: () => import('./views/containerManagement/detail.vue'),
                },
                {
                    path: 'categoryManagement/list',
                    component: () => import('./views/categoryManagement/list.vue'),
                    meta: {
                        btnId: {
                            '127': 0,
                            '128': 3,
                            '152': 1,
                            '153': 2,
                        }
                    }
                },
                {
                    path: 'commodityManagement/list',
                    component: () => import('./views/commodityManagement/list.vue'),
                    meta: {
                        btnId: {
                            '124': 1,
                            '125': 2,
                        }
                    }
                },
                {
                    path: 'commodityManagement/detail/:type/:id/:btn',
                    component: () => import('./views/commodityManagement/detail.vue'),
                },
                {
                    path: 'commodityManagement/dealerDetail/:type/:id/:btn',
                    component: () => import('./views/commodityManagement/dealerDetail.vue'),
                },
                {
                    path: 'merchantCommodityManagement/list',
                    component: () => import('./views/merchantCommodityManagement/list.vue'),
                    meta: {
                        btnId: {
                            '123': 0,
                            '124': 1,
                            '125': 2,
                            '126': 3,
                        }
                    }
                },
                {
                    path: 'merchantCommodityManagement/detail/:type/:id/:auditState',
                    component: () => import('./views/merchantCommodityManagement/detail.vue'),
                },
                {
                    path: 'merchantCommodityManagement/dealerDetail',
                    component: () => import('./views/merchantCommodityManagement/dealerDetail.vue'),
                },
                {
                    path: 'merchantCommodityManagement/dealerEditDetail/:type/:id',
                    component: () => import('./views/merchantCommodityManagement/dealerEditDetail.vue'),
                },
                {
                    path: 'supplierCommodityManagement/list',
                    component: () => import('./views/supplierCommodityManagement/list.vue'),
                },
                {
                    path: 'dealerCommodityManagement/list',
                    component: () => import('./views/dealerCommodityManagement/list.vue'),
                    meta: {
                        btnId: {
                            '123': 0,
                            '124': 1,
                            '125': 2,
                            '126': 3,
                        }
                    }
                },
                {
                    path: 'dealerCommodityManagement/detail/:type/:id',
                    component: () => import('./views/dealerCommodityManagement/detail.vue'),
                },
                {
                    path: 'firmware/list',
                    component: () => import('./views/firmware/list.vue'),
                },
                {
                    path: 'firmware/detail/:type',
                    component: () => import('./views/firmware/detail.vue'),
                }, 
                {
                    path: 'storehouse/list',
                    component: () => import('./views/storehouse/list.vue'),
                    meta: {
                        btnId: {
                            '129': 0,
                            '130': 2,
                            '131': 0,
                            '132': 2,
                            '136': 3,
                            '137': 3,
                        }
                    }
                },
                {
                    path: 'storehouse/bePutInStorage/detail',
                    component: () => import('./views/storehouse/bePutInStorage/detail.vue'),
                },
                {
                    path: 'storehouse/warehouseing/detail',
                    component: () => import('./views/storehouse/warehouseing/detail.vue'),
                },
                // 出货记录
                {
                    path: 'supplierShipment/list',
                    component: () => import('./views/supplierShipment/list.vue'),
                },
                {
                    path: 'lossReportRecord/list',
                    component: () => import('./views/lossReportRecord/list.vue'),
                },
                {
                    path: 'lossReportRecord/detail',
                    component: () => import('./views/lossReportRecord/detail.vue'),
                },
                {
                    path: 'salesOrder/list',
                    component: () => import('./views/salesOrder/list.vue'),
                }, 
                {
                    path: 'salesOrder/detail',
                    component: () => import('./views/salesOrder/detail.vue'),
                },
                {
                    path: 'goodsOnSale/list',
                    component: () => import('./views/goodsOnSale/list.vue'),
                },
                {
                    path: 'goodsOnSale/onSale/list',
                    component: () => import('./views/goodsOnSale/onSale/list.vue'),
                },
                {
                    path: 'refundOrder/list',
                    component: () => import('./views/refundOrder/list.vue'),
                }, 
                {
                    path: 'refundOrder/detail',
                    component: () => import('./views/refundOrder/detail.vue'),
                },
                {
                    path: 'shippingManage/list',
                    component: () => import('./views/shippingManage/list.vue'),
                },
                {
                    path: 'shippingManage/detail',
                    component: () => import('./views/shippingManage/detail.vue'),
                },
                {
                    path: 'merchantManage/witchdrawalRecords/list',
                    component: () => import('./views/merchantManage/witchdrawalRecords/list.vue'),
                },
                {
                    path: 'merchantManage/revenueRecords/list',
                    component: () => import('./views/merchantManage/revenueRecords/list.vue'),
                },
                {
                    path: 'supplierRevenueRecord/list',
                    component: () => import('./views/supplierRevenueRecord/list.vue'),
                },
                {
                    path: 'merchantManage/merchantInfo/detail',
                    component: () => import('./views/merchantManage/merchantInfo/detail.vue'),
                },
                {
                    path: 'merchantManage/merchantInfo/bindAccount',
                    component: () => import('./views/merchantManage/merchantInfo/bindAccount.vue'),
                },
                {
                    path: 'merchantManage/merchant/tabList',
                    component: () => import('./views/merchantManage/merchant/tabList.vue'),
                },
                {
                    path: 'merchantManage/supplier/tabList',
                    component: () => import('./views/merchantManage/supplier/tabList.vue'),
                },
                {
                    path: 'merchantManage/dealer/tabList',
                    component: () => import('./views/merchantManage/dealer/tabList.vue'),
                },
                {
                    path: 'merchantManage/merchant/detail',
                    component: () => import('./views/merchantManage/merchant/detail.vue'),
                },
                {
                    path: 'merchantManage/supplier/detail',
                    component: () => import('./views/merchantManage/supplier/detail.vue'),
                },
                {
                    path: 'merchantManage/dealer/detail',
                    component: () => import('./views/merchantManage/dealer/detail.vue'),
                },
                {
                    path: 'billingRecord/list',
                    component: () => import('./views/billingRecord/list.vue'),
                },
                {
                    path: 'marketingConfig/list',
                    component: () => import('./views/marketingConfig/list.vue'),
                    meta: {
                        btnId: {
                            '156': 0,
                            '157': 1,
                            '158': 2
                        }
                    }
                },
                {
                    path: 'marketingConfig/detail/:type',
                    component: () => import('./views/marketingConfig/detail.vue'),
                },
                {
                    path: 'activeTask/list',
                    component: () => import('./views/activeTask/list.vue'),
                },
                {
                    path: 'activeTask/detail',
                    component: () => import('./views/activeTask/detail.vue'),
                },
                {
                    path: 'bankManagement/list',
                    component: () => import('./views/bankManagement/list.vue'),
                },
                {
                    path: 'groupManagement/list',
                    component: () => import('./views/groupManagement/list.vue'),
                    meta: {
                        btnId: {
                            '154': 0,
                            '155': 1
                        }
                    }
                }, 
                {
                    path: 'groupManagement/detail/:type/:id',
                    component: () => import('./views/groupManagement/detail.vue'),
                },
                {
                    path: 'containerFeedback/list',
                    component: () => import('./views/containerFeedback/list.vue'),
                },
                //租约列表
                {
                    path: 'leaseManagement/list',
                    component: () => import('./views/leaseManagement/list.vue'),
                    meta: {
                        btnId: {
                            '133': 2
                        }
                    }
                },
                {
                    path: 'leaseManagement/detailTab/:type/:id',
                    component: () => import('./views/leaseManagement/detailTab.vue'),
                },
                {
                    path: 'whiteList/list',
                    component: () => import('./views/whiteList/list.vue'),
                },
                {
                    path: 'operationLog/list',
                    component: () => import('./views/operationLog/list.vue'),
                },
                // 批量排房
                {
                    path: 'batchrRangement/list',
                    component: () => import('./views/batchrRangement/list.vue'),
                    meta: {
                        btnId: {
                            '135': 0
                        }
                    }
                },
                {
                    path: 'batchrRangement/detail',
                    component: () => import('./views/batchrRangement/detail.vue'),
                },
                {
                    path: 'batchrRangement/rowHouseLog',
                    component: () => import('./views/batchrRangement/rowHouseLog.vue'),
                },
                // 批量退房
                {
                    path: 'batchrCheckOut/list',
                    component: () => import('./views/batchrCheckOut/list.vue'),
                    meta: {
                        btnId: {
                            '181': 0
                        }
                    }
                },
                {
                    path: 'batchrCheckOut/logDetail',
                    component: () => import('./views/batchrCheckOut/logDetail.vue'),
                },
                // 充值记录
                {
                    path: 'rechargeManagement/list',
                    component: () => import('./views/rechargeManagement/list.vue'),
                },
                {
                    path: 'rechargeManagement/detail/:id',
                    component: () => import('./views/rechargeManagement/detail.vue'),
                },
                // 商家的充值记录
                {
                    path: 'merchantManage/merchantRecharge/list',
                    component: () => import('./views/merchantManage/merchantRecharge/list.vue'),
                },
                // 电车换电-用户列表
                {
                    path: 'tram/user/list',
                    component: () => import('./views/tram/user/list.vue'),
                },
                // 电车换电-用户详情
                {
                    path: 'tram/user/detail',
                    component: () => import('./views/tram/user/detail.vue'),
                },
                // 电车换电-商品管理
                {
                    path: 'tram/commodityManagement/list',
                    component: () => import('./views/tram/commodityManagement/list.vue'),
                    meta: {
                        btnId: {
                            '146': 0,
                            '147': 1,
                        }
                    }
                },
                // 电车换电-订单列表
                {
                    path: 'tram/order/list',
                    component: () => import('./views/tram/order/list.vue'),
                },
                // 电车换电-换电记录
                {
                    path: 'tram/powerChangeRecord/list',
                    component: () => import('./views/tram/powerChangeRecord/list.vue'),
                },
                // 电车换电-告警记录
                {
                    path: 'tram/alarmRecord/list',
                    component: () => import('./views/tram/alarmRecord/list.vue'),
                },
                // 校园管理-订单列表
                {
                    path: 'schoolManagement/order/list',
                    component: () => import('./views/schoolManagement/order/list.vue'),
                },
                // 校园管理-学校管理
                {
                    path: 'schoolManagement/school/list',
                    component: () => import('./views/schoolManagement/school/list.vue'),
                    meta: {
                        btnId: {
                            '175': 0, //要对接 虚设值
                        }
                    }
                },
                // 经销商-经销记录
                {
                    path: 'dealerDistributionRecords/list',
                    component: () => import('./views/dealerDistributionRecords/list.vue'),
                },
                // 经销商收益记录
                {
                    path: 'dealerRevenueRecords/list',
                    component: () => import('./views/dealerRevenueRecords/list.vue'),
                },
            ]
        }
    ]
})
