<template>
  <div class="app-form">
    <el-form ref="form" :model="form" :rules="rules" :validate-on-rule-change="false" v-loading="isShowLoading"
      :disabled="isDisabled" :data-value="entry">
      <div v-for="item in filterShow(set)"
        :class="{'item-box-50': item.isCol50, 'item-box-33': item.isCol33,'item-box-50-4': item.isCol50Label4,'item-box-100-4': item.isCol100Label4, 'item-box-def': !item.isCol50&&item.isCol33&&!item.isCol50Label4&&!item.isCol100Label4, 'item-hidden': item.isHidden}"
        :key="item.key">
        <el-form-item v-if="item.type == 'TitleName'" class="TitleName_title" :label="item.label"
          :prop="item.type != 'table' ? item.key : null">
          <div v-if="item.type == 'TitleName'" class="form-item_TitleName">{{item.subLabel}}</div>
        </el-form-item>
        <el-form-item v-if="item.type == 'ViewDetails'" class="ViewDetails_title" :label="item.label"
          :prop="item.type != 'table' ? item.key : null">
          <div v-if="item.type == 'ViewDetails'" class="form-item_ViewDetails" @click="item.opt.onCheck()">{{item.subLabel}}</div>
        </el-form-item>
        <el-form-item v-else-if="item.type=='selectTip'" :label="item.label" :prop="item.type != 'table' ? item.key : null" v-show="!item.notShow">
          <el-select v-model="form[item.key]"
            :placeholder="(type!='show' && !item.disabled)?'请选择'+item.label:''" :disabled="item.disabled"
            @change="onChangeSelect(item)" :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
          <div class="select__tip">{{item.opt.tip}}</div>
        </el-form-item>
        <el-form-item v-else :label="item.label" :prop="item.type != 'table' ? item.key : null" v-show="!item.notShow">
          <el-input v-if="!item.type" :disabled="item.disabled?true:false" v-model.trim="form[item.key]" clearable
            :placeholder="(type!='show' && !item.disabled)?(item.placeholder?item.placeholder:'请填写'+item.label):''"
            :maxlength="item.maxlength?item.maxlength:50" @keyup.native="keyUpChange(item)"
            :class="{'item-content-67': item.isWidth67}"></el-input>
          <div v-else-if="item.type == 'subTitle'" class="form-item_subTitle">{{item.subLabel}}</div>
          <el-input type="textarea" v-else-if="item.type=='textarea'" v-model="form[item.key]"
            :disabled="item.disabled?true:false" :rows="4" :maxlength="item.maxlength?item.maxlength:1000"
            :placeholder="type!='show'?(item.placeholder?item.placeholder:'请填写'+item.label):''" show-word-limit
            clearable></el-input>
          <el-input-number v-else-if="item.type=='number'" type="number" v-model.number="form[item.key]"
            :min="item.min != null ? item.min : 1" :max="item.max != null ? item.max : 9999"
            :label="item.label ? item.label : '描述文字'"
            :placeholder="type!='show'?(item.placeholder?item.placeholder:'请填写'+item.label):''"
            :disabled="item.disabled?true:false" @change="numberChange(item)"
            :class="{'item-content-67': item.isWidth67,'item-content-100': item.isWidth100}" clearable>
          </el-input-number>
          <div v-else-if="item.type=='link'" @click="item.opt.callback(form)"
            :class="type == 'show' ? 'input-link' : ''">
            <el-input :disabled="item.disabled?true:false" type="text" v-model.trim="form[item.key]"
              :placeholder="type!='show'?(item.placeholder?item.placeholder:'请填写'+item.label):''"></el-input>
          </div>

          <el-input v-else-if="item.type=='password'" :disabled="item.disabled?true:false" type="password"
            v-model.trim="form[item.key]" clearable
            :placeholder="type!='show'?(item.placeholder?item.placeholder:'请填写'+item.label):''"
            :class="{'item-content-67': item.isWidth67}" :maxlength="item.maxlength?item.maxlength:50"
            :readonly="pwReadonlyFlag" @click.native="pwReadonlyFlagBtn"></el-input>
          <el-input v-else-if="item.type=='userPassword'" :maxlength="item.maxlength?item.maxlength:50" :disabled="item.disabled?true:false" type="password"
            v-model.trim="form[item.key]" clearable
            :placeholder="type!='show'?(item.placeholder?item.placeholder:'请填写'+item.label):''" @focus="focusFun(item)"
            :class="{'item-content-67': item.isWidth67}" :readonly="pwReadonlyFlag" @click.native="pwReadonlyFlagBtn">
          </el-input>
          <div class="jump-box" v-else-if="item.type=='selectStores'" :disabled="false" clearable
            @click="jump(item,form[item.key])" :class="{'item-content-67': item.isWidth67}">{{selectStoresShow?'请先添加用户提交，再编辑用户【授权货柜】':form[item.key]}}
          </div>
          <!-- 解决密码框会弹出历史密码的问题 -->
          <el-input v-else-if="item.type=='otherPassword'" :maxlength="item.maxlength?item.maxlength:50" :disabled="item.disabled?true:false" type="text"
            v-model.trim="form[item.key]" clearable
            :placeholder="type!='show'?(item.placeholder?item.placeholder:'请填写'+item.label):''" @focus="focusFun(item)"
            :class="{'item-content-67': item.isWidth67}" :readonly="pwReadonlyFlag" @click.native="pwReadonlyFlagBtn" class="no-autofill-pwd">
          </el-input>
          <el-select v-else-if="item.type=='select-filterables'" v-model="form[item.key]"
            :multiple="item.opt.multiple==false?false:true" filterable :filter-method="selectRemoteFilterMethod(item)"
            :placeholder="type!='show'?'请输入关键词搜索':''" :remote-method="onSelectRemoteMethod"
            :loading="item.opt.isLoading" :disabled="item.disabled" @change="onChangeSelect(item)"
            @focus="onSelectRemotefocus(item)" :popper-class="item.maxHeight?item.maxHeight:''"
            :popper-append-to-body="true" :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
          <el-select v-else-if="item.type=='select-filterable'" v-model="form[item.key]" filterable
            :filter-method="selectRemoteFilterMethod(item)" :placeholder="type!='show'?'请输入关键词搜索':''"
            :remote-method="onSelectRemoteMethod" :loading="item.opt.isLoading" :disabled="item.disabled"
            @change="onChangeSelect(item)" @focus="onSelectRemotefocus(item)"
            :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
          <!-- 解决多选及查询选择的bug -->
          <el-select v-else-if="item.type=='select-filterable-2'" v-model="form[item.key]"
            :multiple="item.opt.multiple==false?false:true" filterable :placeholder="type!='show'?'请输入关键词搜索':''"
            :remote-method="onSelectRemoteMethod" :loading="item.opt.isLoading" :disabled="item.disabled"
            @change="onChangeSelect(item)" @focus="onSelectRemotefocus(item)"
            :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
          <el-select v-else-if="item.type=='select-remote'" v-model="form[item.key]"
            :multiple="item.opt.multiple==false?false:true" filterable remote reserve-keyword
            :placeholder="type!='show'?'请输入关键词搜索':''" :remote-method="onSelectRemoteMethod"
            :loading="item.opt.isLoading" :disabled="item.disabled" @change="onChangeSelect(item)"
            @focus="onSelectRemotefocus(item)" :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
          <!--
            select-remote-filterable
            例子：opt: {
              multiple: false,
              list: [],
              on(keyword, cb) {
                //获取焦点并且list是空数组以及会输入关键字自动调用
                _this.getCompanyList(keyword, cb);//具体获取下拉选项的ajax
              }
            }
            getCompanyList(keyword, cb) {
              cb([{label: '选项名称', value: '值'}]);
            }
          -->
          <el-select v-else-if="item.type=='select-remote-filterable'" v-model="form[item.key]"
            :multiple="item.opt.multiple==false?false:true" filterable
            :filter-method="handleSelectRemoteFilterable(item)" :placeholder="type!='show'?'请输入关键词搜索':''"
            :loading="item.opt.isLoading" :disabled="item.disabled" @change="handleSelectRemoteFilterableChange(item)"
            @focus="handleSelectRemoteFilterableFocus(item)" :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value">
              <span class="select-label">{{ it.label }}</span>
              <span class="select-subLabel">{{ it.subLabel || '' }}</span>
            </el-option>
          </el-select>
          <el-select v-else-if="item.type=='select-filter'" v-model="form[item.key]" filterable
            :multiple="item.opt.multiple==false?false:true" :placeholder="type!='show'?'请输入关键词搜索':''"
            @change="handleSelectRemoteFilterableChange(item)" :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
          <div v-else-if="item.type=='switch'">
             <el-switch v-model="form[item.key]" :disabled="item.disabled"
            active-color="#67C23A" @change="onChangeSelect(item)" inactive-color="#F56C6C"
            :active-text="item.opt && item.opt.activeText ? item.opt.activeText : '启用'"
            :inactive-text="item.opt && item.opt.inactiveText ? item.opt.inactiveText : '禁用'"></el-switch>
            <span class="tipsText">{{item.subLabel}}</span>
          </div>        
          <el-checkbox-group v-else-if="item.type=='checkbox'" v-model="form[item.key]" size="small">
            <el-checkbox v-for="it in item.opt.list" :key="it.id" :label="it.id" border>{{it.name}}</el-checkbox>
          </el-checkbox-group>
          <el-select v-else-if="item.type=='selectCity'" v-model="form[item.key]" filterable
            :placeholder="type!='show'?'请选择'+item.label:''" :disabled="item.disabled" @change="onChangeSelect(item)"
            :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.label"></el-option>
          </el-select>
          <el-select v-else-if="item.type=='select'" v-model="form[item.key]"
            :placeholder="(type!='show' && !item.disabled)?'请选择'+item.label:''" :disabled="item.disabled"
            @change="onChangeSelect(item)" :class="{'item-content-67': item.isWidth67}">
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select>
          <div v-else-if="item.type=='upload-img'">
            <el-upload :action="item.opt.url" accept="image/*" list-type="picture-card" :auto-upload="false"
              :limit="item.opt.limit?item.opt.limit:6" :file-list="form[item.key]" :http-request="item.opt.upload"
              :before-upload="onBeforeUpload(item)" :before-remove="onBeforeRemoveByUploadImg(item)"
              :on-success="onSuccessByUploadImg(item)" :on-preview="onPreviewByUploadImg" :on-exceed="onExceed"
              :disabled="isDisabled" :class="{'upload-disabled': isDisabled}">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div v-else-if="item.type=='upload-img-form'">
            <el-upload accept="image/*" list-type="picture-card" :action="item.opt.url" :file-list="form[item.key]"
              :limit="item.opt.limit?item.opt.limit:6" :on-preview="onPreviewByUploadImg"
              :before-upload="onBeforeUpload(item)" :name="item.opt.name ? item.opt.name: 'file'"
              :before-remove="onBeforeRemoveByUploadImg(item)" :on-success="onSuccessByFormUploadImg(item)"
              :on-remove="onHandleRemove(item)" :on-exceed="onExceed" :disabled="isDisabled"
              :class="{'upload-disabled': isDisabled}">
              <i class="el-icon-plus"></i>
            </el-upload>
            <div class="img-upload-alt">{{item.opt.alt}}</div>
          </div>
          <div v-else-if="item.type=='upload-img-only'">
            <div class="disabled-box" v-if="isDisabled"></div>
            <el-upload class="avatar-uploader" accept="image/*" list-type="picture-card" :action="item.opt.url"
              :file-list="form[item.key]" :show-file-list="false" :on-preview="onPreviewByUploadImg"
              :before-upload="onBeforeUpload(item)" :before-remove="onBeforeRemoveByUploadImg(item)"
              :on-success="onSuccessByUploadImgOnly(item)" :on-remove="onHandleRemove(item)" :on-exceed="onExceed">
              <img v-if="form[item.key] && form[item.key].length"
                :src="item.opt.src?item.opt.src+form[item.key][0].url:form[item.key][0].url" class="avatar" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
            <div class="img-upload-alt">{{item.opt.altKey?item.opt.alt[form[item.opt.altKey]]:item.opt.alt}}</div>
          </div>
          <div v-else-if="item.type=='upload-img-only-one'">
            <div class="disabled-box" v-if="isDisabled"></div>
            <el-upload class="avatar-uploader" accept="image/*" list-type="picture-card" :action="item.opt.url"
              :file-list="form[item.key]" :on-preview="onPreviewByUploadImg" :before-upload="onBeforeUpload(item)"
              :before-remove="onBeforeRemoveByUploadImg(item)" :on-success="onSuccessByUploadImgOnly(item)"
              :on-remove="onHandleRemove(item)" :on-exceed="onExceed"
              :class="{'one-upload-disabled': form[item.key].length>0}">
              <i v-if="form[item.key].length<1" class="el-icon-plus"></i>
            </el-upload>
          </div>
          <el-cascader v-else-if="item.type=='cascader'" :collapse-tags="item.collapseTags" :options="item.opt.list"
            :props="item.opt.props" :separator="'/'" v-model="form[item.key]" @change="onChangeSelect(item)" :filterable="item.filterable">
          </el-cascader>
          <!-- 授权房源，弹框选择 -->
          <div v-else-if="item.type=='cascader-confirm'" class="cascader-confirm-box"
            @click="item.opt && item.opt.click ? item.opt.click(form[item.key]) : null">{{form[item.key]}}</div>
          <lazy-cascader :disabled="isDisabled" v-else-if="item.type=='cascader-lazy'" v-model="form[item.key]"
            filterable :props="item.opt.props" :lazyChangeFlag="lazyChangeFlag" :separator="'/'"
            @change="lazyCascaderChange"></lazy-cascader>
            <select-cascader :disabled="isDisabled" v-else-if="item.type=='select-cascader'"
            :props="item.opt.props" v-model="form[item.key]" :separator="'/'"
            ></select-cascader>
            <select-cascader-advert :disabled="isDisabled" v-else-if="item.type=='select-cascader-advert'"
            :props="item.opt.props" v-model="form[item.key]" :separator="'/'"
            ></select-cascader-advert>
            <select-cascader-stores :disabled="isDisabled" v-else-if="item.type=='select-cascader-stores'"
            :props="item.opt.props" v-model="form[item.key]" :separator="'/'"
            ></select-cascader-stores>
            <select-cascader-gateways :disabled="isDisabled" v-else-if="item.type=='select-cascader-gateway'"
            :props="item.opt.props" v-model="form[item.key]" :separator="'/'"
            ></select-cascader-gateways>
          <el-date-picker v-else-if="item.type=='date'&&item.bforeDateDisable" v-model="form[item.key]" type="date"
            :disabled="item.disabled" :placeholder="type!='show'?'请选择'+item.label:''"
            :class="{'item-content-50': item.isWidth50,'item-content-67': item.isWidth67}"
            :picker-options="pickerOptions"
            ></el-date-picker>
            <el-date-picker v-else-if="item.type=='date'" v-model="form[item.key]" type="date"
            :disabled="item.disabled" :placeholder="type!='show'?'请选择'+item.label:''"
            :class="{'item-content-50': item.isWidth50,'item-content-67': item.isWidth67}"></el-date-picker>
          <el-date-picker v-else-if="item.type=='date-time'" v-model="form[item.key]" type="datetime"
            :disabled="item.disabled" :placeholder="type!='show'?'请选择'+item.label:''"
            :class="{'item-content-67': item.isWidth67}"></el-date-picker>
          <el-time-picker v-model="form[item.key]" v-else-if="item.type=='time'" :disabled="item.disabled"
            :value-format="item.opt && item.opt.format ? item.opt.format  : 'HH:mm:ss'"
            :format="item.opt && item.opt.format ? item.opt.format  : 'HH:mm:ss'"
            @change="onChangeTime(item, form[item.key])" :picker-options="{
              selectableRange:  item.opt && item.opt.min && item.opt.max ? `${item.opt.min} - ${item.opt.max}` : ''
            }" :class="{'item-content-67': item.isWidth67}"></el-time-picker>
          <el-time-picker v-model="form[item.key]" v-else-if="item.type=='range-time'" range-separator="至" is-range
            start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"
            @change="item.opt && item.opt.change ? item.opt.change(form[item.key]) : null"
            :value-format="item.opt && item.opt.format ? item.opt.format : 'HH:mm:ss'"
            :format="item.opt && item.opt.format ? item.opt.format : 'HH:mm:ss'"
            :class="{'item-content-67': item.isWidth67}"></el-time-picker>
          <el-date-picker v-else-if="item.type=='range-date'" v-model="form[item.key]" type="daterange" unlink-panels
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            :class="{'item-content-67': item.isWidth67}"></el-date-picker>
          <el-date-picker v-else-if="item.type=='datetimerange'" v-model="form[item.key]" type="datetimerange"
            unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            :picker-options="{disabledDate: onDatetimerangeDisabledDate(item)}"
            :class="{'item-content-67': item.isWidth67}"></el-date-picker>
          <el-date-picker v-else-if="item.type=='daterange'" v-model="form[item.key]" type="daterange" unlink-panels
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            :picker-options="{disabledDate: onDatetimerangeDisabledDate(item)}"
            :class="{'item-content-67': item.isWidth67}"></el-date-picker>
          <el-upload v-else-if="item.type=='upload-file'" :action="item.opt && item.opt.url ? item.opt.url : '#'"
            :limit="item.opt.limit?item.opt.limit:6" :file-list="form[item.key]"
            :accept="item.opt && item.opt.accept ?  item.opt.accept : ''" :multiple="item.opt.multiple?multiple:false"
            :on-change="onChangeFile(item)" :before-upload="item.opt.beforeUploadFile || (() => true)"
            :on-success="onSuccessByFormUploadFile(item)" :on-exceed="onExceed" :on-remove="onHandleRemove(item)"
            :before-remove="item.opt.remove" :on-progress="item.opt.onProgress">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">{{item.opt.tip}}</div>
          </el-upload>
          <div class="img-list" v-else-if="item.type == 'img-list'">
            <img v-for="it in form[item.key]" :key="it.src" :src="it.src" @click="showBig(it.src)" />
          </div>
          <div class="img" v-else-if="item.type == 'img'">
            <img :src="form[item.key]" @click="showBig(form[item.key])" />
          </div>
          <div class="table" v-else-if="item.type == 'table'">
            <el-row class="table__row table__header">
              <el-col v-for="it in item.opt.columns" :key="it.key">{{it.label}}</el-col>
            </el-row>
            <i class="not_table__action el-icon-circle-plus"
              v-if="type!='show' && !item.opt.isDisabled && !form[item.key].length"
              @click="form[item.key].push(JSON.parse(JSON.stringify(item.opt.temp)))"></i>
            <el-form :model="form[item.key][index]" :rules="item.rules" ref="table_form" :key="index"
              v-for="(it, index) in form[item.key]">
              <el-row class="table__row">
                <el-col v-for="(i,k) in item.opt.columns" :key="k">
                  <el-form-item :prop="i.key">
                    <input v-if="!i.type" type="text" :readonly="item.opt && item.opt.isDisabled"
                      :disabled="type!='show'?false:true" v-model="form[item.key][index][i.key]" clearable />
                    <input v-else-if="i.type == 'number'" type="number" :max="i.max"
                      :readonly="item.opt && item.opt.isDisabled" :disabled="type!='show'?false:true"
                      v-model.number="form[item.key][index][i.key]" clearable />
                    <el-input-number v-else-if="i.type == 'number-pro'" v-model="form[item.key][index][i.key]"
                      :max="i.max?i.max:'Infinity'" :min="i.min!=undefined?i.min:'-Infinity'"
                      :disabled="type!='show'?false:true" :controls="false">
                    </el-input-number>
                  </el-form-item>
                </el-col>
                <i class="table__action el-icon-remove" v-if="type!='show' && !item.opt.isDisabled"
                  @click="form[item.key].splice(index, 1)"></i>
                <i class="table__action el-icon-circle-plus"
                  v-if="type!='show' && !item.opt.isDisabled && index == form[item.key].length -1"
                  @click="form[item.key].push(JSON.parse(JSON.stringify(item.opt.temp)))"></i>
              </el-row>
            </el-form>
          </div>
          <div class v-else-if="item.type == 'slot'">
            <slot :name="item.opt.slotName"></slot>
          </div>
          <div class v-else-if="item.type == 'radio'">
            <el-radio-group v-model="form[item.key]">
              <el-radio v-for="(items,indexs) in item.opt.list" :disabled="items.disabled ? true : false"
                :label="items.id" :key="indexs">{{items.label}}
              </el-radio>
            </el-radio-group>
          </div>
          <div class v-else-if="item.type == 'radioHasText'">
            <el-radio-group v-model="form[item.key]">
              <el-radio v-for="(items,indexs) in item.opt.list" :disabled="items.disabled ? true : false"
                :label="items.id" :key="indexs" style="padding-top:14px;">
              <span>{{items.label}}</span>
              <div style="color:#909399;padding-top:6px;foxt-size:13px;padding-left:25px;">{{items.text}}</div>
              </el-radio>
            </el-radio-group>
          </div>
          <!-- <div class="map" v-else-if="item.type == 'map'">
            <div class="map-container" :ref="item.key"></div>
          </div>
          <div class="map-util" v-else-if="item.type == 'mapUtil'">
            <div class="map-container" :ref="item.key"></div>
            <el-input :disabled="item.disabled?true:false" v-model="form[item.key]" clearable
              :placeholder="type!='show'?(item.placeholder?item.placeholder:'请选择'+item.label):''"
              :maxlength="item.maxlength?item.maxlength:50"></el-input>
          </div> -->
          <el-input v-else-if="item.type == 'tel'" :disabled="item.disabled?true:false" v-model="form[item.key]"
            clearable :placeholder="type!='show'?(item.placeholder?item.placeholder:'请填写'+item.label):''"></el-input>
          <div v-else-if="item.type == 'datetimerange-list'">
            <div class="datetimerange-list-box" v-for="(it, idx) in form[item.key]" :key="idx">
              <el-date-picker v-model="form[item.key][idx]" :type="item.rangeType?item.rangeType:'daterange'"
                :picker-options="{disabledDate: onDatetimerangeDisabledDate(item)}" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              <el-button class="daterange-list__del" v-if="type!='show'" type="danger" icon="el-icon-delete" circle
                @click="onDelDateRangeList(item, form[item.key], idx)"></el-button>
            </div>
            <el-button class="daterange-list__add" v-if="type!='show'" round
              @click="onAddDateRangeList(item, form[item.key])">增加</el-button>
            <div class="datetimerange-list-alt" v-if="item.opt.length">最多添加{{item.opt.length}}个</div>
          </div>
          <div v-else-if="item.type == 'weekly-range'">
            <el-checkbox-group v-model="form[item.key]">
              <el-checkbox v-for="it in weekly" :key="it" :label="it" border>{{weekConfig[it]}}</el-checkbox>
            </el-checkbox-group>
            <div class="weekly-range-alt" v-if="item.opt && item.opt.alt">{{item.opt.alt}}</div>
          </div>
          <div class="monthly-range" v-else-if="item.type == 'monthly-range'">
            <div class="monthly-range-box">
              <div class="monthly-range-but" v-bind:class="{ 'monthly-range-but_act': it.isAct }"
                v-for="(it, idx) in monthly" :key="idx" @click="onMonthlyRange(it, idx, item)">{{it.idx}}</div>
            </div>
            <div class="monthly-range-alt" v-if="item.opt && item.opt.alt">{{item.opt.alt}}</div>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
  import Vue from "vue";

  export default {
    props: {
      opt: {
        type: Object,
        default () {
          return {};
        }
      },
      entry: {
        type: String,
        default () {
          return "";
        }
      },
      isFor: {
        type: Boolean,
        default () {
          return false;
        }
      }
    },
    data() {
      let _this = this;
      let _monthly = [];
      for (let i = 1; i <= 31; i++) {
        _monthly.push({
          idx: i,
          isAct: false
        });
      }
      let _weekly = [];
      for (let i = 0; i < 7; i++) {
        _weekly.push(i);
      }
      return {
        form: {},
        rules: {},
        set: [],
        monthly: _monthly,
        weekly: _weekly,
        weekConfig: [
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
          "星期日"
        ],
        type: "",
        isDisabled: false,
        dialogVisible: false,
        dialogImageUrl: "",
        isShowLoading: true,
        pwReadonlyFlag: true,
        selectStoresShow: false,
        cascaderProps: {
          lazy: true,
          lazyLoad(node, resolve) {
            const {
              level
            } = node;
            setTimeout(() => {
              const nodes = Array.from({
                  length: level + 1
                })
                .map(item => ({
                  value: ++id,
                  label: `选项${id}`,
                  leaf: level >= 2
                }));
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
              resolve(nodes);
            }, 1000);
          }
        },
        lazyChangeFlag: 0,
      };
    },
    created() {
      console.log("app-form created!", this.$props);
      if (this.isFor) {
        this.init();
      }
    },
    activated() {
      console.log("app-form activated!", this.$props);
      if (!this.isFor) {
        this.init();
      }
      this.pwReadonlyFlag = true; //解决编辑用户后，添加用户会自动填充账号密码的问题
    },
    mounted() {},
    deactivated() {
      this.rules = {};
      this.form = {};
      this.set = [];
    },
    watch: {
      opt: {
        deep: true,
        handler: function (news, olds) {
          if (this.type == "show") {
            return;
          }
          for (let i = 0; i < this.$props.opt.form.length; i++) {
            let item = this.$props.opt.form[i];
            if (
              item.rules &&
              item.filterRule &&
              this.form[item.filterRule.key] == item.filterRule.value
            ) {
              this.rules[item.key] = item.filterRule.rules;
            } else if (
              item.rules &&
              item.filterRule &&
              this.form[item.filterRule.key] != item.filterRule.value
            ) {
              this.rules[item.key] = item.rules;
            }

          }
          news.form.forEach(item => {
            if (item.type == 'cascader-confirm') {
              this.form[item.key] = item.showText;
            }
          })
        }
      }
    },
    methods: {
      pwReadonlyFlagBtn() {
        this.pwReadonlyFlag = false
      },
      //初始化
      init() {
        this.type = this.$route.params.type;
        let _rules = {};
        let _form = {};
        let _this = this;
        for (let i = 0; i < this.$props.opt.form.length; i++) {
          let item = this.$props.opt.form[i];
          if (item.rules) {
            for (let j = 0; j < item.rules.length; j++) {
              const it = item.rules[j];
              if (it.required) {
                item.isRequired = true;
              }
              if (it.required != undefined && !it.validator) {
                it.message = "必须填写" + item.label;
              }
              if (it.regexp) {
                it.validator = (rule, value, callback) => {
                  if (value.match(it.regexp)) {
                    callback(new Error(it.message));
                  } else {
                    callback();
                  }
                };
              }
              if (it._validator) {
                it.validator = (rule, value, callback) => {
                  it._validator(this.form, rule, value, callback);
                };
              }
            }
            _rules[item.key] = item.rules;
          }
          if (item.type == "tel") {
            if (!_rules[item.key]) {
              _rules[item.key] = [];
            }
            _rules[item.key].push({
              validator: (rule, value, callback) => {
                let reg = /^1[3-9](\d){9}$/;
                if (!value) {
                  callback();
                } else if (!reg.test(value)) {
                  callback(new Error("手机号码格式不对"));
                } else {
                  callback();
                }
              }
            });
          }
          if (item.type == "select-filterables" || item.type == "select-filterable") {
            _this.cache.delR("selectFilterable" + item.key);
          }
          if (item.opt && item.opt.parent) {
            item.disabled = true;
          }
          if (
            !item.only ||
            item.only == this.type ||
            item.only.indexOf(this.type) != -1
          ) {
            if (item.defaultValue && this.type == "add") {
              _form[item.key] = item.defaultValue;
            } else {
              switch (item.type) {
                case "select-remote":
                  _form[item.key] = item.opt.multiple == false ? "" : [];
                  break;
                  // case "cascader-lazy":
                  //   _this.$refs['cascaderRefArea'].inputValue='落落公/1栋';
                  //   // _form[item.key] = '落落公/1栋';
                  //   break;
                case "switch":
                  _form[item.key] = false;
                  break;
                case "upload-img":
                case "img-list":
                case "upload-img-only":
                case "upload-img-only-one":
                case "upload-img-form":
                case "upload-file":
                case "checkbox":
                case "radio":
                case "weekly-range":
                case "monthly-range":
                  _form[item.key] = [];
                  break;
                case "table":
                  item.opt.temp = {};
                  for (let i = 0; i < item.opt.columns.length; i++) {
                    const it = item.opt.columns[i];
                    item.opt.temp[it.key] = "";
                  }
                  if (item.opt && item.opt.default) {
                    _form[item.key] = JSON.parse(
                      JSON.stringify(item.opt.default)
                    );
                  } else {
                    _form[item.key] = [JSON.parse(JSON.stringify(item.opt.temp))];
                  }
                  break;
                case "select":
                  if (item.opt && item.value) {
                    _form[item.key] = JSON.parse(JSON.stringify(item.value));
                  } else {
                    _form[item.key] = "";
                  }
                  break;
                case "datetimerange-list":
                  _form[item.key] = [{}];
                  break;
                default:
                  _form[item.key] = "";
              }
            }
            if (item.init) {
              item.init(_form[item.key]);
            }
          }
        }

        if (this.type != "show") {
          this.rules = _rules;
        }

        if (typeof this.$props.opt.init == "function") {
          _form = this.$props.opt.init(_form);
        }

        this.form = _form;
        this.set = this.$props.opt.form;
        this.$props.opt._form = _form;
        this.isDisabled = false;

        if (this.type == "show" || this.type == "edit") {
          this.selectStoresShow = false
          if (this.type == "show") {
            this.isDisabled = true;
          }
          var id = this.$route.params.id;

          if (typeof this.$props.opt.get == "function") {
            this.$props.opt.get({
              id: id,
              cb(data) {
                // 父级有值，解除disabled
                _this.$props.opt.form.forEach(item => {
                  // if (
                  //   item.opt &&
                  //   item.opt.parent &&
                  //   _this.form[item.opt.parent]
                  // ) {
                  //   item.disabled = false;
                  // }
                  // if (item.opt && item.opt.change) {
                  //   item.opt.change(_this.form[item.key], data => {
                  //     item.opt.list = data;
                  //   });
                  // }

                  // if (_this.type == "show" && item.type == "map") {
                  //   let ele = _this.$refs[item.key][0];
                  //   let it = data[item.key];
                  //   let center = new qq.maps.LatLng(it.latitude, it.longitude);
                  //   let map = new qq.maps.Map(ele, {
                  //     center: center,
                  //     zoom: 16
                  //   });
                  //   let marker = new qq.maps.Marker({
                  //     position: center,
                  //     map: map
                  //   });
                  // }

                  // if (_this.type == "edit" && item.type == "mapUtil") {
                  //   _this.setMapUtil(item, data[item.mapData]);
                  // }
                  if (
                    _this.type == "edit" &&
                    item.type == "monthly-range" &&
                    data[item.key]
                  ) {
                    //初始化日历的isAct值
                    for (let j = 0; j < _this.monthly.length; j++) {
                      let it = _this.monthly[j];
                      it.isAct = false;
                    }

                    let days = data[item.key];
                    for (let i = 0; i < days.length; i++) {
                      for (let j = 0; j < _this.monthly.length; j++) {
                        const it = _this.monthly[j];
                        if (days[i] == it.idx) {
                          it.isAct = true;
                          break;
                        }
                      }
                    }
                  }
                  if (
                    _this.type == "show" &&
                    item.type == "monthly-range" &&
                    data[item.key]
                  ) {
                    //初始化日历的isAct值
                    for (let j = 0; j < _this.monthly.length; j++) {
                      let it = _this.monthly[j];
                      it.isAct = false;
                    }

                    let days = data[item.key];
                    for (let i = 0; i < days.length; i++) {
                      for (let j = 0; j < _this.monthly.length; j++) {
                        const it = _this.monthly[j];
                        if (days[i] == it.idx) {
                          it.isAct = true;
                          break;
                        }
                      }
                    }
                  }
                  if (
                    _this.type == "edit" &&
                    item.type == "datetimerange-list" &&
                    !data[item.key]
                  ) {
                    data[item.key] = [{}];
                  }
                  if (
                    _this.type == "edit" &&
                    item.type == "weekly-range" &&
                    !data[item.key]
                  ) {
                    data[item.key] = [];
                  }
                });

                _this.form = data;
                _this.$props.opt.form.forEach(item => {
                  // if (
                  //   item.opt &&
                  //   item.opt.parent &&
                  //   _this.form[item.opt.parent]
                  // ) {
                  //   item.disabled = false;
                  // }
                  if (item.opt && item.opt.change) {
                    item.opt.change(_this.form[item.key], data => {
                      if (data.type && data.type == "adRangeType") {
                        _this.$props.opt.form.forEach(it => {
                          if (it.key == data.key) {
                            it.opt.list = data.list;
                            _this.form[it.key] = data.defaultValue;
                          }
                        })
                      } else {
                        item.opt.list = data;
                      }
                    });
                  }
                  // if (_this.type == "edit" && item.type == "mapUtil") {
                  //   _this.setMapUtil(item, data[item.mapData]);
                  // }
                });

                _this.isShowLoading = false;
              }
            });
          }
        }
        if (this.type == "add" || this.type == "edit") {
          this.$tel.wait(this.$props.entry, cb => {
            let valid = true;
            if (_this.$refs.table_form) {
              _this.$refs.table_form.forEach(item => {
                item.validate((resulte, obj) => {
                  if (!resulte) {
                    valid = false;
                  }
                });
              });
            }
            if (valid) {
              cb(_this.$refs.form, _this.form);
            }
          });
        }
        if (this.type == "add") {
          this.selectStoresShow = true
          // let mapList = [];
          // for (let i = 0; i < this.$props.opt.form.length; i++) {
          //   let item = this.$props.opt.form[i];
          //   if (item.type == "mapUtil") {
          //     mapList.push(item);
          //   }
          // }
          // this.$nextTick(() => {
          //   for (let i = 0; i < mapList.length; i++) {
          //     let item = mapList[i];
          //     this.setMapUtil(item);
          //   }
          // });
          //添加的时候有addInit方法先执行完该方法，isShowLoading才为false。没有addInit方法isShowLoading直接false
          if (typeof this.$props.opt.addInit == "function") {
            this.$props.opt.addInit({
              cb(data) {
                if (data) {
                  _this.isShowLoading = false;
                }
              }
            })
          } else {
            this.isShowLoading = false;
          }
        }
      },
      //辅助显示隐藏
      filterShow(list) {
        let _this = this;
        let showList = [];
        list.forEach((item, index) => {
          // 配置过滤显示
          if (item.filters) {
            if (
              Object.prototype.toString.call(item.filters) === "[object Array]"
            ) {
              let is = true;
              for (let i = 0; i < item.filters.length; i++) {
                const element = item.filters[i];
                if (
                  Object.prototype.toString.call(element.value) ==
                  "[object Array]" ?
                  element.value.indexOf(_this.form[element.key]) == -1 :
                  element.value != _this.form[element.key]
                ) {
                  is = false;
                  break;
                }
              }
              if (is) {
                showList.push(item);
              }
            } else if (typeof item.filters == "function") {
              let is = item.filters();
              if (is) {
                showList.push(item);
              }
            } else if (
              item.filters &&
              item.filters.key &&
              item.filters.value &&
              item.filters.value == _this.form[item.filters.key] &&
              !item.filters.notShow
            ) {
              showList.push(item);
            } else if (
              item.filters &&
              item.filters.key &&
              item.filters.value &&
              item.filters.value != _this.form[item.filters.key] &&
              item.filters.notShow
            ) {
              showList.push(item);
            }
          }

          // 配置编辑和详情模式的过滤显示
          if (
            !item.filters &&
            (!item.only ||
              item.only == this.type ||
              item.only.indexOf(this.type) != -1)
          ) {
            showList.push(item);
          }
        });
        return showList;
      },
      lazyCascaderChange(data) {
        let _this = this;
        let arr = data.current;
        let oldData = data.oldData;
        if (oldData && oldData.length > 0 && oldData[0][0] == '0' && arr.length > 1 && arr[0][0] ==
          '0') { //上一次为全选,这次选择不能为全选
          console.log('上一次为全选')
          arr.shift();
          _this.$set(_this.form, "cascader", arr)
            ++_this.lazyChangeFlag;
        } else if (oldData && oldData.length > 0 && oldData[0][0] == '-1' && arr.length > 1) { //未授权房源
          if (arr[0][0] == '0') { //默认未授权房源，然后选择全部
            _this.form["cascader"] = [
              ["0"]
            ];
          } else {
            arr.shift();
            _this.$set(_this.form, "cascader", arr)
          }
          ++_this.lazyChangeFlag;
        } else if (oldData && oldData.length > 0 && oldData[0][0] != '0' && arr.length > 0 && arr[0][0] == '0') {
          console.log('本次为全选')
          _this.form["cascader"] = [
            ["0"]
          ];
        }

      },
      onChangeSelect(item) {
        let _this = this;
        this.$props.opt.form.forEach(data => {
          if (data.opt && data.opt.parent && data.opt.parent == item.key) {
            _this.form[data.key] = "";
            data.disabled = false;
            if (data.type == "switch") {
              _this.form[data.key] = false;
            }
          }
        });
        if (item.opt && item.opt.change) {
          item.opt.change(
            _this.form[item.key],
            data => {
              if (data.type && data.type == "adRangeType") {
                _this.$props.opt.form.forEach(it => {
                  if (it.key == data.key) {
                    it.opt.list = data.list;
                    _this.form[it.key] = data.defaultValue;
                  }
                })
              } else {
                item.opt.list = data;
              }

            },
            _this.form
          );

        }
      },
      numberChange(item) {
        let _this = this;
        if (item.opt && item.opt.keyupNative) {
          _this.$nextTick(() => {
            _this.form[item.key] = Math.round(_this.form[item.key] * 100) / 100;
          })
        }
      },
      keyUpChange(item) {
        let _this = this;
        if (item.opt && item.opt.keyupNative) {
          _this.form[item.key] = this.clearNoNum(_this.form[item.key].toString())
        }
      },
      //控制只能输入数字和小数点后2位
      clearNoNum(value) {
        value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符   
        value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
        value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数  

        if (value.indexOf(".") < 0 && value != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
          value = parseFloat(value);
        }
        return value;
      },
      //配合远程搜索多选下拉框回调确认item
      onSelectRemotefocus(item) {
        let list = this.cache.getR("selectFilterable" + item.key);
        if (!list) {
          let data = JSON.parse(JSON.stringify(item.opt.list));
          this.cache.setR("selectFilterable" + item.key, data);
        } else {
          item.opt.list = list;
        }
        this.cache.setR("selectRemoteItem", item);
      },
      //远程搜索多选下拉框回调
      onSelectRemoteMethod(query) {
        let item = this.cache.getR("selectRemoteItem");
        if (item && item.opt && item.opt.on) {
          item.opt.on(query);
        }
      },
      //放大图片
      showBig(src) {
        this.dialogImageUrl = src;
        this.dialogVisible = true;
      },
      //配合图片上传确认item
      onPreviewByUploadImg(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      //图片上传回调
      onSuccessByUploadImg(item) {
        return (response, file, fileList) => {
          this.form[item.key] = fileList;
        };
      },
      //表单类型图片上传回调
      onSuccessByFormUploadImg(item) {
        return (response, file, fileList) => {
          if (response.code != 0) {
            fileList.pop();
            return this.$message({
              message: "图片上传失败",
              type: "warning"
            });
          }
          if (item.opt.isUseFileList) {
            this.form[item.key] = fileList;
            return;
          }
          if (item.urlKey) {
            this.form[item.key].push({
              url: response.data[item.urlKey]
            });
          } else {
            this.form[item.key].push({
              url: response.data.picPath
            });
          }
        };
      },
      //表单图片单张上传
      onSuccessByUploadImgOnly(item) {
        return (response, file, fileList) => {
          let keyName = item.opt.urlKey ? item.opt.urlKey : "picPath";
          this.form[item.key] = [{
            url: response.data[keyName]
          }];
        };
      },
      // 图片删除
      onHandleRemove(item) {
        return (file, fileList) => {
          this.form[item.key] = fileList;
        };
      },
      //图片上传的图片删除
      onBeforeRemoveByUploadImg(item) {
        return (file, fileList) => {
          return new Promise((resolve, reject) => {
            let _this = this;
            let is = true;
            if (file.raw) {
              if(item.opt.limitFileNameLength) {
                item.opt.remove(file,fileList)
                resolve()
              }
              if ( item.opt.limitwidthLength && !_this.form[item.opt.altKey]) {
                  item.opt.remove(file, fileList);
                  resolve();
                }
              if(item.opt.limitwidthLength && !item.opt.altKey) {
                  item.opt.remove(file, fileList);
                  resolve();
              }
              if (item.opt.limitwidthLength) {
                let limitwidthLength;
                if(item.opt.altKey) {
                  limitwidthLength = item.opt.limitwidthLength[_this.form[item.opt.altKey]];
                }else {
                  limitwidthLength = item.opt.limitwidthLength;
                }
                const isSize = new Promise(function (resolve, reject) {
                  let reader = new FileReader()
                  reader.readAsDataURL(file)
                  reader.onload = () => {
                    let img = new Image()
                    img.src = reader.result
                    img.onload = () => {
                      if (img.naturalWidth == limitwidthLength[0] && img.naturalHeight == limitwidthLength[1]) {
                        resolve(img)
                      } else {
                        reject()
                      }
                    }
                  }
                }).then((resultImg) => {
                  return file;
                }, () => {
                  return Promise.reject();
                });
                is = item.opt.limitType.indexOf(file.raw.type) != -1 && (file.raw.size / 1024 / 1024 <
                  item.opt.limitSize && !isSize);
              } else if(item.opt.limitFileNameLength) {
                is = !this.checkLimitFileName(item,file)
              } else {
                is = ["image/png", "image/jpeg"].indexOf(file.raw.type) != -1 && (file.raw.size / 1024 / 1024 <
                  30);
              }

            }
            if (is) {
              this.$confirm("确认删除此图片吗？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                })
                .then(() => {
                  item.opt.remove(file, fileList);
                  resolve();
                })
                .catch(function () {
                  reject();
                });
            } else {
              resolve();
            }
          });
        };
      },

      // 改变时间选择
      onChangeTime(item, val) {
        this.$props.opt.form.forEach(data => {
          if (
            data.opt &&
            data.opt.parent &&
            data.opt.parent == item.key &&
            this.form[data.opt.parent]
          ) {
            this.form[data.key] = "";
            data.disabled = false;
            this.$forceUpdate();
          }
          if (data.opt && data.opt.parent && !this.form[data.opt.parent]) {
            data.disabled = true;
            this.form[data.key] = "";
          }
        });
        if (item.opt && item.opt.change) {
          item.opt.change(val);
        }
      },
      // 文件本地上传执行的回调函数
      onChangeFile(item) {
        return (response, file, fileList) => {
          if (item.opt || !item.opt.url) {
            if (file[0]["raw"]) {
              this.form[item.key].push(file[0]["raw"]);
            }
          }
        };
      },

      // 文件上传执行的回调函数
      onSuccessByFormUploadFile(item) {
        return (response, file, fileList) => {
          if (file["raw"]) {
            this.form[item.key].push(file["raw"]);
          }
        };
      },
      //文件上传数量超过限制
      onExceed() {
        this.$message({
          message: "文件上传数量超过限制，请删除后重新上传",
          type: "warning"
        });
      },
      //自定义搜索
      selectRemoteFilterMethod(item) {
        return label => {
          let list = this.cache.getR("selectFilterable" + item.key);
          let _list = [];
          for (let i = 0; i < list.length; i++) {
            if (list[i].label.indexOf(label) != -1) {
              _list.push(list[i]);
            }
          }
          item.opt.list = _list;
        };
      },
      //远程搜索选择框
      handleSelectRemoteFilterable(item) {
        return keyword => {
          if (item.opt.on) {
            item.opt.on(keyword, data => {
              this.handleSelectRemoteFilterableUtil(data, item);
            });
          }
        };
      },
      //远程搜索选择框获取焦点
      handleSelectRemoteFilterableFocus(item) {
        if (item.opt.list.length == 0 && item.opt.on) {
          item.opt.on("", data => {
            item.opt.list = data;
          });
        }
        if (item.opt.focus) {
          item.opt.focus(item.opt.list, data => {
            item.opt.list = data;
          });
        }
      },
      //远程搜索选择框值改变
      handleSelectRemoteFilterableChange(item) {
        if (item.opt && item.opt.change) {
          item.opt.change(
            this.form[item.key],
            data => {
              this.handleSelectRemoteFilterableUtil(data, item);
            },
            this.form
          );
        }
      },

      /**
       * handleSelectRemoteFilterable辅助方法
       */
      handleSelectRemoteFilterableUtil(data, item) {
        if (item.opt.multiple != false) {
          let mustList = [];
          for (let i = 0; i < this.form[item.key].length; i++) {
            const val = this.form[item.key][i];
            for (let j = 0; j < item.opt.list.length; j++) {
              const tag = item.opt.list[j];
              if (tag.value == val) {
                let is = true;
                for (let k = 0; k < data.length; k++) {
                  const ele = data[k];
                  if (ele.value == val) {
                    is = false;
                    break;
                  }
                }
                if (is) {
                  mustList.push(tag);
                }
                break;
              }
            }
          }
          item.opt.list = data.concat(mustList);
        } else {
          item.opt.list = data;
        }
      },

      /**
       * 地图选择组件辅助
       */
      // setMapUtil(item, latLng) {
      //   if (!item.opt) {
      //     item.opt = {};
      //   }

      //   let _this = this;
      //   let _key = item.key + "MapUtil";
      //   let _center = null;

      //   if (latLng && latLng.address) {
      //     _this.form[item.key] = latLng.address;
      //   }

      //   if (latLng && latLng.latitude && latLng.longitude) {
      //     _center = new qq.maps.LatLng(latLng.latitude, latLng.longitude);
      //     _this.form[_key] = {
      //       lat: latLng.latitude,
      //       lng: latLng.longitude
      //     };
      //   } else {
      //     _center = new qq.maps.LatLng(39.91485, 116.403765);
      //   }

      //   // item.opt.ele = this.$refs[item.key][0];
      //   // item.opt.map = new qq.maps.Map(item.opt.ele, {
      //   let _map = new qq.maps.Map(this.$refs[item.key][0], {
      //     center: _center,
      //     zoom: 16
      //   });

      //   if (latLng && latLng.latitude && latLng.longitude) {
      //     setMarker(_center);
      //   } else {
      //     //获取城市列表接口设置中心点
      //     let citylocation = new qq.maps.CityService({
      //       complete: function (result) {
      //         _map.setCenter(result.detail.latLng);
      //         _this.form[_key] = result.detail.latLng;
      //       }
      //     });
      //     //调用searchLocalCity();方法    根据用户IP查询城市信息。
      //     citylocation.searchLocalCity();
      //   }

      //   qq.maps.event.addListener(_map, "click", function (event) {
      //     let latitude = event.latLng.getLat();
      //     let longitude = event.latLng.getLng();
      //     let center = new qq.maps.LatLng(latitude, longitude);
      //     setMarker(center);
      //     let geocoder = new qq.maps.Geocoder({
      //       complete: function (result) {
      //         console.log("腾讯地图选择：" + result.detail.address);
      //         _this.form[item.key] = result.detail.address;
      //         _this.form[_key] = result.detail.location;
      //       }
      //     });
      //     let coord = new qq.maps.LatLng(latitude, longitude);
      //     geocoder.getAddress(coord);
      //   });

      //   function setMarker(center) {
      //     let _marker = _this.cache.getR("appFormMarker");
      //     if (_marker) {
      //       _marker.setMap(null);
      //     }
      //     let marker = new qq.maps.Marker({
      //       position: center,
      //       map: _map
      //     });
      //     _this.cache.setR("appFormMarker", marker);
      //   }
      // },

      checkLimitFileName(item,file) {
        let fileName = file.name
        let lastIndex = fileName.lastIndexOf('.')
        fileName = fileName.substring(0,lastIndex)
        return fileName.length > item.opt.limitFileNameLength
      },

      /**
       * 上传文件之前的钩子
       */
      onBeforeUpload(item) {
        return file => {
          let _this = this;
          let is=false;
          let size=false;
          if(item.opt.limitFileNameLength) {
            if(this.checkLimitFileName(item,file)) {
              this.$message.error(`上传的图片名称长度不能超过${item.opt.limitFileNameLength}`)
              return false
            }
          }
          if(item.opt.limitType){
            is = item.opt.limitType.indexOf(file.type) != -1;
          }
          else{
            is = ["image/png", "image/jpeg"].indexOf(file.type) != -1;
          }
          // let is = ["image/png", "image/jpeg"].indexOf(file.type) != -1;
          if(item.opt.limitSize){
             size = file.size / 1024 / 1024 > item.opt.limitSize;
          }
          else{
            size = file.size / 1024 / 1024 > 30;
          }
           if (item.opt.limitwidthLength && item.opt.altKey && !_this.form[item.opt.altKey]) {
              this.$message.error(item.opt.beforeUploadTip);
              return false;
            }
          if (!is) {
            console.log("上传文件之前的钩子", item, file, is);
            if(item.opt.limitType){
              item.opt.limitType.forEach(typeItem=>{
                if(typeItem=="image/jpeg"){
                   this.$message.error("只能上传jpg图片");
                }
              })
              
            }
            else{
              this.$message.error("只能上传图片");
            }
            
            return false;
          } else if (size) {
            this.$message.error("上传的图片不能超过"+(item.opt.limitSize?item.opt.limitSize:30)+"M");
            return false;
          }
          if (item.opt.limitwidthLength) {
            let limitwidthLength;
            if(item.opt.altKey) {
              limitwidthLength = item.opt.limitwidthLength[_this.form[item.opt.altKey]];
            }else {
              limitwidthLength = item.opt.limitwidthLength;
            }
            const isSize = new Promise(function (resolve, reject) {
              let reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = () => {
                let img = new Image()
                img.src = reader.result
                img.onload = () => {
                  console.log('img.naturalWidth:',img.naturalWidth)
                  console.log('img.naturalHeight:',img.naturalHeight)
                  console.log('img.width:',img.width)
                  console.log('img.height:',img.height)
                  if (img.naturalWidth == limitwidthLength[0] && img.naturalHeight == limitwidthLength[1]) {
                    resolve()
                  } else {
                    _this.$message.error('请上传' + limitwidthLength[0] + '*' + limitwidthLength[1] + 'px的图片');
                    reject()
                  }
                }
              }
            }).then(() => {
              return file;
            }, () => {
              return Promise.reject();
            });
            return isSize
          } else {
            return true;
          }



        };
      },

      onDatetimerangeDisabledDate(item) {
        return date => {
          if (item.opt) {
            if (item.opt.start && item.opt.limit) {
              let start = moment(item.opt.start).subtract(1, "days");
              let end = moment()
                .add(item.opt.limit[0], item.opt.limit[1])
                .endOf("years");
              if (moment(date).isBetween(start, end)) {
                return false;
              } else {
                return true;
              }
            }
          } else {
            return false;
          }
        };
      },

      /**
       * 增加日期时间范围列表项
       */
      onAddDateRangeList(item, data) {
        if (item.opt && item.opt.length && data.length < item.opt.length) {
          data.push({});
        }
      },

      /**
       * 删除日期时间范围列表项
       */
      onDelDateRangeList(item, data, idx) {
        if (data.length > 1) {
          data.splice(idx, 1);
        }
      },

      /**
       * 选择每月日期范围
       */
      onMonthlyRange(it, idx, item) {
        if (this.type != "show") {
          it.isAct = !it.isAct;
          if (it.isAct) {
            this.form[item.key].push(it.idx);
          } else {
            this.form[item.key].splice(this.form[item.key].indexOf(it.idx), 1);
          }
        }
      },
      // 清空输入框的值 解决 编辑用户，用户密码拿不到明文，修改密码值修改一半时存在的bug
      focusFun(item) {
        this.form[item.key] = "";
      },
      jump(val,val2){
        if(this.type == 'show') {
          this.$router.push({
            path: "/main/user/selectedContainer",
            query: {userId:val.value}
          });
        }else if(this.type == 'edit'){
          this.$router.push({
            path: "/main/user/authorized",
            query: {userId:val.value}
          });
        }else{
          return
        }
      }
        
    },
    computed: {
       // 禁用今日之后的日期
      pickerOptions: function(){
          return {
            disabledDate(time) {
              return time.getTime() > Date.now();
            }
          }
      },
    }
  };
</script>
<style lang="scss">
  .app-form {
    .el-form {
      margin: 0 auto;
      max-width: 1100px;

      .el-form-item {
        display: flex;
        justify-content: flex-start;
      }

      .el-form-item::before,
      .el-form-item::after {
        display: none !important;
      }

      .TitleName_title {
        .el-form-item__label {
          text-align: left;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .form-item_ViewDetails {
        font-weight: 600;
        font-size: 15px;
        color: #30BAC1;
        display: inline-block;
        cursor: pointer;
      }

      .el-form-item__label {
        flex: 0.13;
      }

      .el-form-item__content {
        margin: 0;
        flex: 1;
      }

      .item-box-50 {
        display: inline-block;
        width: 50%;

        .el-form-item__label {
          flex: 0.3;
        }
      }
      .item-box-50-4{
        display: inline-block;
        width: 50%;

        .el-form-item__label {
          flex: 0.4;
        }
      }
      .item-box-100-4{
        .el-form-item__label {
          flex: 0.165;
        }
      }

      .item-box-33 {
        display: inline-block;
        width: 33%;

        .el-form-item__label {
          flex: none;
          width: 100px;
        }
      }

      .item-content-67 {
        width: 67%;
      }

      .item-content-100 {
        width: 100%;
      }

      .item-box {
        width: 100%;
      }

      .input-link input {
        color: #30BAC1 !important;
        cursor: pointer !important;
      }

      .item-hidden {
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
      }

      .img-list {
        white-space: nowrap;
        overflow-x: auto;

        img {
          margin-right: 10px;
          height: 160px;
          cursor: pointer;
        }
      }

      .img {
        img {
          max-width: 500px;
          cursor: pointer;
        }
      }

      .table {
        .el-form {
          border-bottom: solid 1px #ddd;
          border-left: solid 1px #ddd;

          .el-col {
            border-right: solid 1px #ddd;

            .el-form-item__error {
              display: none;
            }
          }
        }
      }

      .table__header {
        background-color: #eeeeee;

        .el-col {
          padding: 0 10px;
        }
      }

      .table__row {
        display: -webkit-box;

        .el-form {
          display: -webkit-box;
        }

        .el-col {
          float: initial;
          -webkit-box-flex: 1;
          color: #606266;
          font-size: 14px;
          text-align: center;

          .el-form-item__content {
            line-height: initial;
          }

          input {
            vertical-align: middle;
            padding: 0 10px;
            width: 90%;
            height: 30px;
            box-sizing: border-box;
            border: none;
            border-right: none;
            text-align: center;
            border-bottom: none;
          }

          .el-input-number--mini {
            position: relative;
            left: -1px;
            width: 100%;
            border: none;
            height: 28px;
            border-bottom: none;
          }

          .el-input--mini {
            input {
              border: none;
              height: 27px;
            }
          }
        }

        // .el-col:nth-last-child(2) {
        //   input {
        //     border-right: solid 1px #ccc !important;
        //   }
        // }
        .table__action {
          position: absolute;
          top: 5px;
          right: -35px;
          font-size: 30px;
          cursor: pointer;
        }

        .el-icon-circle-plus {
          color: #67c23a;
          right: -70px;
        }

        .el-icon-remove {
          color: #f56c6c;
        }
      }

      .not_table__action {
        position: absolute;
        top: 5px;
        right: -35px;
        font-size: 30px;
        cursor: pointer;
        color: #67c23a;
        right: -70px;
      }

      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .el-upload:hover {
        border-color: #30BAC1;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }

      .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }

      .disabled-box {
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      // .map-container {
      //   height: 300px;
      // }

      // .map-util {
      //   input {
      //     margin-top: 22px;
      //   }
      // }

      .img-upload-alt {
        font-size: 12px;
        color: #999;
      }

      .daterange-list__add {
        margin-top: 10px;
        min-width: 120px;
      }

      .daterange-list__del {
        margin-left: 10px;
      }

      .datetimerange-list-box {
        margin-bottom: 8px;
      }

      .datetimerange-list-alt {
        margin-left: 10px;
        font-size: 12px;
        color: #999999;
        display: inline-block;
      }

      .weekly-range-alt,
      .monthly-range-alt {
        font-size: 12px;
        color: #999999;
      }

      .monthly-range-box {
        display: inline-block;
        width: 420px;
        border: solid 1px #eee;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .monthly-range-but {
        float: left;
        margin-bottom: 4px;
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        cursor: pointer;
      }

      .monthly-range-but_act {
        color: #fff;
        background-color: #30BAC1;
      }
    }

    .form-item_subTitle {
      position: relative;
      top: 30px;
      margin-bottom: 10px;
      left: -30px;
      font-size: 12px;
      color: #999;
      margin-left: 0;
    }

    .tipsText {
      position: relative;
      top: 1px;
      padding-left: 10px;
      color: #999;
      font-size: 14px;
    }

    .el-range-input {
      color: #333 !important;
      cursor: default !important;
    }

    .el-range-editor {
      cursor: default !important;
    }
  }

  .select-subLabel {
    float: right;
    font-size: 12px;
    color: #999;
  }

  .select__tip {
    font-size: 12px;
    margin-left: 10px;
    color: orange;
  }

  .cascader-confirm-box {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* 限制在一个块元素显示的文本的行数 */
    -webkit-box-orient: vertical;
    /* 垂直排列 */
    word-break: break-all;
    /* 内容自动换行 */
  }

  .jump-box{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .maxHeight400 .el-select-dropdown__wrap {
    max-height: 400px !important;
  }

  @media only screen and (max-width: 500px) {
    .app-form .el-form .el-form-item__label {
      flex: 0.3;
    }

    // .app-form .tipsText {
    //   top: -30px;
    //   left: 30px;
    // }

    .app-form .el-form .item-box-50 {
      width: 100%;
    }
  }
  @font-face{
  font-family: text-security-disc;
  src: url("./css/font/text-security-disc.woff");
 }
  .no-autofill-pwd {
      .el-input__inner {
          -webkit-text-security: disc !important;
          font-family: text-security-disc !important;
      }
    }
</style>